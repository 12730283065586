import styled from 'styled-components'
import { primaryColor } from 'styles/colors'

interface IDotProps {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 30px 40px;
  position: relative;

  button {
    cursor: pointer;
  }

  button.back {
    position: absolute;
    left: 30px;
    border: none;
    background: transparent;
    color: #ddd;
    transition: all 0.4s;
    width: 30px;
    height: 30px;

    :hover {
      svg {
        color: ${primaryColor};
      }
    }

    @media (max-width: 1015px) {
      display: none;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;

  @media (max-width: 900px) {
    font-size: 11px;

    button.dot {
      height: 25px;
      width: 25px;
    }
  }
`

export const Line = styled.div`
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #ddd;
`

export const Dot1 = styled.button<IDotProps>`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 2px solid #ddd;
  background: ${({ active }) => active ? primaryColor : '#ddd'};
  z-index: 5;
  position: relative;

  span {
    position: absolute;
    width: max-content;
    font-weight: 600;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    color: ${primaryColor};
  }
`

export const Dot2 = styled.button<IDotProps>`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 2px solid #ddd;
  background: ${({ active }) => active ? primaryColor : '#ddd'};
  z-index: 5;
  position: relative;

  span {
    position: absolute;
    width: max-content;
    font-weight: 600;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    color: ${primaryColor};
  }
`

export const Dot3 = styled.button<IDotProps>`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 2px solid #ddd;
  background: ${({ active }) => active ? primaryColor : '#ddd'};
  z-index: 5;
  position: relative;

  span {
    position: absolute;
    width: max-content;
    font-weight: 600;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    color: ${primaryColor};
  }
`
