import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { addHours, isBefore } from 'date-fns'
import api, { setApiAuthToken, setLogoutCallback } from 'services/api'
import { useHistory } from 'react-router'

export interface IUser {
  id: number
  name: string
  email: string
  image: string
}

interface IAuthState {
  user: IUser
  token: string
}

interface IRegisterData {
  name: string
  email: string
  password: string
  confirmPassword: string
}

interface IRegisterDataSocialMedia {
  name: string
  email: string
  photo: string
  userId: string
  userToken: string
  loginType: 'Facebook' | 'Google'
}

export interface IUserProgress {
  type: 'VideoAulas' | 'VideoArtigos'
  progress: number
  objectId: string
  coverImageUrl: string
  rate: number
  slug: string
  name: string
  completed_videos_ids: string[]
}

interface IAuthContext {
  user: IUser
  token: string
  userProgress: IUserProgress[] | undefined
  signIn: (email: string, password: string) => Promise<void>
  signUp: (register: IRegisterData) => Promise<void>
  signUpSocialMedia: (
    register: IRegisterDataSocialMedia,
  ) => Promise<boolean | undefined>
  signOut: () => void
  setUserProgress: (values: IUserProgress[]) => void
  updateUserObject: (user: IUser) => void
}

const AuthContext = createContext({} as IAuthContext)

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@HealthPlay:token')
    const time = localStorage.getItem('@HealthPlay:tokenExp')
    const tokenExp = time ? new Date(time) : null
    const user = localStorage.getItem('@HealthPlay:user')

    if (tokenExp && isBefore(new Date(), tokenExp) && token && user) {
      return { token, user: JSON.parse(user) } as IAuthState
    }
    return {} as IAuthState
  })
  const [userProgress, setUserProgressState] = useState<
    IUserProgress[] | undefined
  >(() => {
    const userProgress = localStorage.getItem('@HealthPlay:userProgress')

    if (userProgress && userProgress !== 'undefined') {
      return JSON.parse(userProgress)
    }
  })

  const signIn = useCallback(async (email: string, password: string) => {
    const {
      data: { user, token },
    } = await api.post('/auth/login', {
      email: email.toLowerCase().trim(),
      password: password.trim(),
    })

    if (user && token) {
      localStorage.setItem('@HealthPlay:token', token)
      localStorage.setItem(
        '@HealthPlay:userProgress',
        JSON.stringify(user.userProgress),
      )
      delete user.userProgress
      localStorage.setItem('@HealthPlay:user', JSON.stringify(user))
      localStorage.setItem(
        '@HealthPlay:tokenExp',
        addHours(new Date(), 24).toString(),
      )

      setData({ token, user })
    }
  }, [])

  const signUp = useCallback(async (register: IRegisterData) => {
    const {
      data: { user, token },
    } = await api.post('/auth/register', register)

    if (user && token) {
      localStorage.setItem('@HealthPlay:token', token)
      localStorage.setItem(
        '@HealthPlay:userProgress',
        JSON.stringify(user.userProgress),
      )
      delete user.userProgress
      localStorage.setItem('@HealthPlay:user', JSON.stringify(user))
      localStorage.setItem(
        '@HealthPlay:tokenExp',
        addHours(new Date(), 24).toString(),
      )

      setData({ token, user })
    }
  }, [])

  const signUpSocialMedia = useCallback(
    async (register: IRegisterDataSocialMedia) => {
      const {
        data: { user, token, newUser },
      } = await api.post('/auth/enter-by-social-media', register)

      if (user && token) {
        localStorage.setItem('@HealthPlay:token', token)
        localStorage.setItem(
          '@HealthPlay:userProgress',
          JSON.stringify(user.userProgress),
        )
        delete user.userProgress
        localStorage.setItem('@HealthPlay:user', JSON.stringify(user))
        localStorage.setItem(
          '@HealthPlay:tokenExp',
          addHours(new Date(), 24).toString(),
        )

        setData({ token, user })
        return newUser
      }
    },
    [],
  )

  const signOut = useCallback(() => {
    localStorage.removeItem('@HealthPlay:token')
    localStorage.removeItem('@HealthPlay:user')
    localStorage.removeItem('@HealthPlay:tokenExp')
    localStorage.removeItem('@HealthPlay:userProgress')
    localStorage.removeItem('@HealthPlay:PricingCard')
    setData({} as IAuthState)
    history.replace('/app')
  }, [history])

  useEffect(() => {
    if (data && data.token) {
      setApiAuthToken(data.token)
      setLogoutCallback(signOut)
    } else {
      setApiAuthToken()
    }
  }, [data])

  const setUserProgress = useCallback((values: IUserProgress[]) => {
    setUserProgressState(values)
    localStorage.setItem('@HealthPlay:userProgress', JSON.stringify(values))
  }, [])

  const updateUserObject = useCallback((user: IUser) => {
    localStorage.setItem('@HealthPlay:user', JSON.stringify(user))
    setData((old) => ({ ...old, user }))
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        userProgress,
        signIn,
        signUp,
        signUpSocialMedia,
        signOut,
        setUserProgress,
        updateUserObject,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): IAuthContext {
  const context = useContext(AuthContext)
  if (!context) throw new Error('useAuth must be used within an AuthProvider')
  return context
}

export { useAuth, AuthProvider }
