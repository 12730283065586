import { shade } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px;
  position: relative;
  color: #333;
  width: 500px;
  display: flex;
  flex-direction: column;

  button.close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  h2 {
    color: #21a4ac;
    margin: 0 0 20px;
  }

  h4 {
    margin: 20px 0 5px;
  }

  .star-container {
    i {
      font-size: 26px;
    }
  }

  textarea {
    border: 1px solid #333;
    border-radius: 8px;
    padding: 5px;
  }

  button.finish {
    align-self: center;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #21a4ac;
    color: white;
    border: 1px solid #21a4ac;
    margin-top: 20px;
    transition: 300ms ease-in-out;

    :hover {
      background-color: ${shade(0.2, '#21a4ac')};
    }

    :disabled {
      background-color: #aaa;
      color: #000;
      border-color: #aaa;
    }
  }
`

export const Teacher = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    strong {
      color: #21a4ac;
      margin-bottom: 5px;
    }

    span {
      font-size: 13px;
      font-weight: 500;
    }
  }
`
