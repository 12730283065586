import React, { useEffect, useState } from 'react'
import PricingCard from 'components/PricingCard'
import WhatAreYouBuying from 'components/WhatAreYouBuying'
import { Main } from '../styles'
import api from '../../../services/api'
import { useToast } from 'hook/Toast'

export interface IPricingCard {
  clinicas: number
  code: number
  description: string[]
  freeDays: number
  id: string
  isBestOption: true
  name: string
  period: string
  price: number
  refundTime: number
  tagPrice: string
  installments: number
  monthlyPrice: number
}

interface IPricingCardGroup {
  [key: string]: IPricingCard[]
}

const SelectPlan: React.FC = () => {
  const [pricings, setPricings] = useState<IPricingCard[]>([])

  const { addToast } = useToast()

  useEffect(() => {
    async function loadData() {
      try {
        localStorage.removeItem('@HealthPlay:PricingCard')
        const { data } = await api.get<IPricingCardGroup>('/plans')
        const allValues = data?.[0] ?? []
        setPricings(allValues)
      } catch (error) {
        addToast({
          title: 'Ops...',
          description:
            'Não foi possível carregar os planos.\nTente novamente mais tarde.',
          time: 5000,
          type: 'error',
        })
        setPricings([])
      }
    }

    loadData()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Main>
        {pricings.map((v) => (
          <PricingCard key={v.id} value={v} />
        ))}
      </Main>
      <WhatAreYouBuying />
    </>
  )
}

export default SelectPlan
