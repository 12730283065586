import React from 'react'

import {
  Container,
  ItemContainer,
  Icon,
  TextContainer,
  Title,
  Description,
} from './styles'

const items = [
  {
    iconSrc: '/assets/icons/professional_qualified.svg',
    title: 'Profissionais Qualificados',
    description:
      'Especialistas de diversas áreas para auxiliar você nessa jornada',
  },
  {
    iconSrc: '/assets/icons/weight_2.svg',
    title: 'Atualização Profissional',
    description: 'Tudo o que é novidade nas áreas da saúde e do movimento humano em uma única plataforma.',
  },
  {
    iconSrc: '/assets/icons/play_circled.svg',
    title: 'Conteúdo Online',
    description: 'Vídeos para acessar em qualquer lugar a qualquer hora',
  },
]

export default function BannerStripe() {
  return (
    <Container>
      {items.map((item, idx) => (
        <ItemContainer key={idx}>
          <Icon src={item.iconSrc} />
          <TextContainer>
            <Title>{item.title}</Title>
            <Description>{item.description}</Description>
          </TextContainer>
        </ItemContainer>
      ))}
    </Container>
  )
}
