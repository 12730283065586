import styled, { css } from 'styled-components'
import { primaryColor } from './colors'

interface ICategoryButtonProps {
  active?: boolean
}

export const PrimaryText = styled.div`
  color: ${primaryColor};
  font-size: 16px;
`

export const DarkText = styled.div`
  color: black;
  font-size: 16px;
`
export const DarkSubTitle = styled(DarkText)`
  font-weight: bold;
`

export const Section = styled.section`
  padding: 48px 6%;
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    padding: 20px 6% 24px 6%;
  }
`

export const SectionTitle = styled.h1`
  color: ${primaryColor};
  font-size: 32px;
  align-self: center;
`

export const CourseAreaTitle = styled.h2`
  color: #292929;
  font-weight: 700;
  font-size: 1.6rem;
  margin-left: 20px;
  margin-top: 5%;
`

export const CategoryAreaTitle = styled.h2`
  color: black;
  font-size: 22px;
  margin-left: 16px;
`

export const SmallTitle = styled(PrimaryText)`
  font-size: 13px;
`

export const VideoInstructorTitle = styled(PrimaryText)`
  h1 {
    font-size: 22px;
    font-weight: bold;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }
`

export const VideoDurationTitle = styled(PrimaryText)`
  font-size: 18px;
  font-weight: bold;
`

export const UnderlinedTitle = styled.div`
  display: inline-block;
  color: ${primaryColor};
  font-weight: 700;
  font-size: 18px;
  border-bottom: 5px solid ${primaryColor};
`
export const CleanLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
`

export const CategoryButtonContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
`

export const CategoryButton = styled.button.attrs<ICategoryButtonProps>(
  (props: ICategoryButtonProps) => ({
    disabled: props.active,
  }),
)<ICategoryButtonProps>`
  border: 2px solid #21a4ac;
  ${({ active }) =>
    active
      ? css`
          cursor: default;
          color: #fff;
          background: #21a4ac;
        `
      : css`
          cursor: pointer;
          background: #fff;
          color: #333;
        `}
  border-radius: 100px;
  padding: 15px 20px;
  margin: 10px;
  transition: all 0.4s;

  :hover {
    color: #fff;
    background: #21a4ac;
  }
`
