import React, { useEffect, useState } from 'react'
import { Section /*, CourseAreaTitle */ } from 'styles/'

import CourseAreaScroll from 'components/CourseAreaScroll/'
import CourseMyCourses from 'components/CourseMyCourses/'
// import BannerCarousel from 'components/BannerCarousel/'
import BannerStripe from 'components/BannerStripe/'
import HeaderHome from 'components/HeaderHome/'
// import WebinarInfo from 'components/WebinarInfo/'
// import PartnersStripe from 'components/PartnersStripe/'

import api from 'services/api'
import { useToast } from 'hook/Toast'
import { useAuth } from 'hook/Auth'
import { IHomeCourse } from '@types'
import Loading from 'components/Loading'
import Button from 'components/Button'
import { BannerWrapper } from 'screens/LandingPage/styles'

interface ICarouselCourseData {
  aulas: IHomeCourse[]
  banners: {
    image: string
    link: string
  }[]
  webinar: {
    slug: string
    name: string
    thumbnail: string
    link: string
    description: string
    teacher: {
      id: string
      name: string
    }
    datetime: {
      formatted: string
      isAfterToday: boolean
      raw: string
    }
  }
  free: IHomeCourse[]
  partners: {
    alt: string
    link: string
    image: string
  }[]
}

const Home: React.FC = () => {
  const [homeData, setHomeData] = useState<ICarouselCourseData>(
    {} as ICarouselCourseData,
  )
  const [isLoading, setIsLoading] = useState(true)

  const { addToast } = useToast()
  const { user, userProgress } = useAuth()

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      try {
        const { data } = await api.get('/home')
        setHomeData(data)
      } catch (err) {
        addToast({
          title: 'Ops...Ocorreu algum erro. Tente novamente.',
        })
      } finally {
        setIsLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line multiline-ternary
  return isLoading ? (
    <Loading show={isLoading} opacity={1} />
  ) : (
    <>
      {/* <BannerCarousel images={homeData.banners} /> */}
      <HeaderHome />

      <BannerStripe />
      {/* <Section>
        {user && userProgress && userProgress.length > 0 && (
          <CourseMyCourses name="Meus cursos" courses={userProgress} />
        )}
        {homeData?.aulas?.length > 0 && (
          <CourseAreaScroll
            name="Novidades"
            courses={homeData?.aulas}
            type="aula"
          />
        )} */}
        {/* {homeData?.webinar && (
          <>
            <CourseAreaTitle>
              {homeData.webinar.datetime.isAfterToday ? 'Próximo' : 'Último'}{' '}
              Webinar
            </CourseAreaTitle>
            <WebinarInfo {...homeData.webinar} />
          </>
        )} */}
        {/* {homeData?.free?.length > 0 && (
          <CourseAreaScroll name="Gratuitos" courses={homeData?.free} />
        )} */}
        {/* <CourseAreaTitle>Parceiros</CourseAreaTitle>
        {homeData?.partners?.length > 0 && (
          <PartnersStripe items={homeData.partners} />
        )} */}
      {/* </Section> */}
      <BannerWrapper style={{ backgroundColor: '#292929', height: 120, margin: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
       <Button onClick={() => window.open('https://healthplay.pro/', 'blank')} >CONHEÇA A PLATAFORMA</Button>
      </BannerWrapper>
    </>
  )
}

export default Home
