import styled from 'styled-components'

export const Info = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 10px;

  div {
    color: #aaa;

    strong {
      color: #888;
    }

    ul {
      padding: 0;

      li {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
`
