import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  ContentLayer,
  LevelContainer,
  LevelIcon,
  PlayIcon,
  Duration,
} from './styles'

interface VideoCardProps extends React.InputHTMLAttributes<HTMLDivElement> {
  coverImageUrl: string
  duration?: string
  level?: number
  publish?: boolean
  url: string
  showPlay?: boolean
}

const VideoCard: React.FC<VideoCardProps> = ({
  coverImageUrl,
  duration,
  level,
  publish,
  url,
  onClick,
  showPlay = true,
  ...rest
}) => {
  const history = useHistory()
  const levelIcons = []
  if (level) {
    for (let i = 0; i < 5; i++) {
      levelIcons.push(<LevelIcon key={i} active={i < level} />)
    }
  }

  return (
    <Container imageSrc={coverImageUrl} {...rest}>
      <ContentLayer>
        <LevelContainer>{level && levelIcons}</LevelContainer>
        {showPlay && publish && (
          <PlayIcon
            onClick={
              onClick ||
              (() => {
                history.push(url)
                window.location.reload()
              })
            }
          />
        )}
        <Duration>{duration && duration}</Duration>
      </ContentLayer>
    </Container>
  )
}

export default VideoCard
