import React from 'react'
import VideoPlayer from 'components/VideoPlayer'
// import { VideoInstructorTitle, VideoDurationTitle, PrimaryText, SmallTitle } from 'styles/'
import { Container, VideoContainer } from './styles'
import { useVideo } from 'hook/Vimeo'
import { AiOutlineEye, AiOutlineLike } from 'react-icons/ai'
import { TimeUpdateEvent } from '@u-wave/react-vimeo'

interface IVideoInfo {
  // videoUrl?: string
  professor: string
  duration: string
  description: string
  views?: number
  likes?: number
  onTimeUpdate?: (event: TimeUpdateEvent) => void
}

const VideoContent: React.FC<IVideoInfo> = ({ professor, duration, description, views, likes, onTimeUpdate }) => {
  const { url } = useVideo()

  return (
    <Container>
      <VideoContainer>
        {url && <VideoPlayer onTimeUpdate={onTimeUpdate} />}
        <div className="views-likes">
          <div style={{ display: 'flex' }}>
            {views && (
              <span>
                <AiOutlineEye /> {views} visualizações
              </span>
            )}
            {likes && (
              <span>
                <AiOutlineLike /> {likes} curtidas
              </span>
            )}
          </div>
          {/* <button type="button" onClick={() => alert('Concluir aula')}>
            Concluir aula
          </button> */}
        </div>
      </VideoContainer>
      {/* {!user && (
        <InfoContainer>
          <div>
            <SmallTitle>Professor</SmallTitle>
            <VideoInstructorTitle>{professor}</VideoInstructorTitle>
            <SmallTitle style={{ margin: '20px 0 5px' }}>Duração</SmallTitle>
            <VideoDurationTitle>
              {duration}
            </VideoDurationTitle>
            <SmallTitle style={{ margin: '20px 0 5px' }}>Descrição</SmallTitle>
            <PrimaryText style={{ fontWeight: 600 }}>{description}</PrimaryText>
          </div>

          <StartButton>Inicar aula</StartButton>
        </InfoContainer>
      )} */}
    </Container>
  )
}

export default VideoContent
