import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPlan } from 'types/IPlan'

interface IPlansReducerState {
  plans?: IPlan[]
  currentPlan?: IPlan
}

const initialState: IPlansReducerState = {}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    updatePlansAction: (state, action: PayloadAction<IPlan[] | undefined>) => {
      state.plans = action.payload
    },
    updateCurrentPlanAction: (
      state,
      action: PayloadAction<IPlan | undefined>,
    ) => {
      state.currentPlan = action.payload
    },
  },
})

export const { updateCurrentPlanAction, updatePlansAction } = plansSlice.actions

export default plansSlice.reducer
