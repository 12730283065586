/* eslint-disable no-tabs */
import React, { useEffect } from 'react'
import BreadCrumb from 'components/BreadCrumb'
import { Container } from './styles'
import { useHistory, withRouter } from 'react-router'
import SelectPlan from './SelectPlan'
import Register from './Register'
import { useAuth } from 'hook/Auth'
import { useToast } from 'hook/Toast'

const Pricing = withRouter(({ location: { pathname } }) => {
  const history = useHistory()
  const { user } = useAuth()
  const { addToast } = useToast()

  useEffect(() => {
    const pricingCard = localStorage.getItem('@HealthPlay:PricingCard')
    const pricingInfo = localStorage.getItem('@HealthPlay:PricingInfo')

    if (!user) {
      history.push('/app/cadastre-se')
      addToast({
        title: 'Ops...',
        description: 'Você precisa estar logado para seguir com esta ação.',
        time: 5000,
      })
      return
    }

    if (!pricingCard && !pricingInfo) {
      history.push('/app/planos/selecione-o-plano')
      return
    }
    if (pricingCard && !pricingInfo) {
      history.push('/app/planos/cadastro-dos-dados')
      return
    }
    if (pricingCard && pricingInfo) {
      history.push('/app/planos/finalizacao')
      return
    }

    history.push('/app/planos/selecione-o-plano')
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <BreadCrumb />
      {pathname.includes('selecione-o-plano') && <SelectPlan />}
      {pathname.includes('cadastro-dos-dados') && <Register />}
      {pathname.includes('finalizacao') && <Register finished />}
    </Container>
  )
})

export default Pricing
