import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
  InputHTMLAttributes,
} from 'react'

import { useField } from '@unform/core'
import { Container } from './styles'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  defaultPreview?: string
}

const InputFile: React.FC<IProps> = ({ name, defaultPreview, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, registerField, error } = useField(name)
  const [preview, setPreview] = useState(defaultPreview || null)

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    setPreview(null)

    if (file) {
      const previewURL = URL.createObjectURL(file)

      setPreview(previewURL)
    }
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
        setPreview(null)
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value)
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      <Container>
        {preview && <img src={preview} alt="Preview" />}
        <span>Adicionar imagem</span>
        <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
      </Container>
      {error && <small style={{ color: '#fa4545' }}>{error}</small>}
    </>
  )
}

export default InputFile
