import React, { useEffect } from 'react'
import { FiAlertCircle, FiXCircle, FiInfo, FiCheckCircle } from 'react-icons/fi'
import { IToastMessage, useToast } from '../../../hook/Toast'

import { Container } from './styles'

interface IToastProps {
  message: IToastMessage
  style: object
}

const icons = {
  info: <FiInfo size={18} />,
  error: <FiAlertCircle size={18} />,
  success: <FiCheckCircle size={18} />,
}

const Toast: React.FC<IToastProps> = ({ message, style }) => {
  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, message.time || 3000)

    return () => clearTimeout(timer)
  }, [removeToast, message])

  return (
    <Container
      key={message.id}
      type={message.type}
      // hasdescription={'false'}
      hasdescription={!!message?.description}
      style={style}
    >
      {icons[message.type || 'info']}

      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={16} />
      </button>
    </Container>
  )
}

export default Toast
