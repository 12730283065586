import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import * as yup from 'yup'
import {
  Main,
  Form,
  Label,
  Left,
  Right,
  PlansContainer,
  Plan,
  CreditCardContainer,
} from './styles'
import getValidationErrors from 'utils/getValidationErrors'
import { useToast } from 'hook/Toast'
import { useHistory } from 'react-router'

import Button from 'components/Button'
import api from 'services/api'
import Select from 'components/Select'
import Input from 'components/Input'
import { useAuth } from 'hook/Auth'
import states from '../Pricing/Register/states'
import Loading from 'components/Loading'
import { FaCheck, FaInfoCircle, FaPencilAlt } from 'react-icons/fa'
import InputFile from 'components/InputFile'
import InputCheck from 'components/InputCheck'
import toBase64 from 'utils/toBase64'
import Modal from 'components/Modal'
import InputMask from 'components/InputMask'

import { generateCardHash } from 'services/pagarme'

import mastercardImage from 'assets/mastercard.png'

interface IUserCurrentPlan {
  user: {
    name: string
    email: string
    cpf: string
    phone: string
    image: string
    payment: {
      card_brand: string
      card_digits: string
      renew_payment: boolean
    }
    address: {
      cep: string
      uf: string
      city: string
      neighborhood: string
      street: string
      number: string
      complement: string
    }
  }
  plans: {
    id: string
    title: string
    value: number
    period: number
    isCurrent: boolean
  }[]
}

interface ILeftFormData {
  name: string
  cpf?: string
  email: string
  image?: File
}

interface IRightFormData {
  cep: string
  uf: string
  city: string
  neighborhood: string
  street: string
  number: string
  complement: string
}

interface IModalFormData {
  cardNumber: string
  card_holder_name: string
  card_expiration_date: string
  card_cvv: string
  cpf: string
  phone: string
  renew_payment?: string[]
}

const Profile: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<string>('')
  const [userCurrentPlan, setUserCurrentPlan] = useState({} as IUserCurrentPlan)

  const leftFormRef = useRef<FormHandles>(null)
  const rightFormRef = useRef<FormHandles>(null)
  const cardFormRef = useRef<FormHandles>(null)

  const { addToast } = useToast()
  const { token, signOut, updateUserObject } = useAuth()
  const history = useHistory()

  useEffect(() => {
    async function loadData() {
      setLoading(true)

      try {
        const { data } = await api.get<IUserCurrentPlan>('/user/my-profile', {
          headers: { authorization: token },
        })
        setUserCurrentPlan(data)
        setCurrentPlan(data.plans?.find((v) => v.isCurrent)?.id || '')
      } catch (error) {
        const err = error as any
        if (err.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para isto',
          })
          signOut()
          history.replace('/')
        } else {
          addToast({
            type: 'error',
            title: 'OPS...',
            description: 'Ocorreu algum erro, tente novamente',
          })
        }
      } finally {
        setLoading(false)
      }
    }

    loadData()
    // eslint-disable-next-line
  }, [reload])

  const handleSubmitLeftForm = useCallback(
    async (values: ILeftFormData): Promise<void> => {
      setLoading(true)
      leftFormRef.current?.setErrors({})

      try {
        await yup
          .object()
          .shape({
            name: yup.string().required('O nome é obrigatório'),
            cpf: yup.string().notRequired(),
            phone: yup
              .string()
              .matches(/\(\d{2}\)\s{1}\d{5}-\d{4}/, {
                message: 'Digite um número válido',
                excludeEmptyString: true,
              })
              .notRequired(),
            email: yup
              .string()
              .email('Digite um e-mail válido')
              .required('O e-mail é obrigatório'),
            image: yup.string().notRequired(),
          })
          .validate(
            {
              ...values,
              image: values.image ? await toBase64(values.image) : undefined,
            },
            {
              abortEarly: false,
            },
          )

        const { data } = await api.post(
          '/user/update-profile',
          {
            ...values,
            image: values.image ? await toBase64(values.image) : undefined,
          },
          {
            headers: { authorization: token },
          },
        )

        addToast({
          title: 'Sucesso!',
          description: 'Dados alterados com sucesso.',
          type: 'success',
        })
        updateUserObject(data.user)
        setReload((old) => !old)
      } catch (error) {
        const err = error as any
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err)
          leftFormRef.current?.setErrors(errors)
        } else if (err.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para isto',
          })
          signOut()
          history.replace('/')
        } else {
          addToast({
            type: 'error',
            title: 'OPS...',
            description: 'Ocorreu algum erro, tente novamente',
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [addToast, history, token, signOut, updateUserObject],
  )

  const handleSubmitRightForm = useCallback(
    async (values: IRightFormData): Promise<void> => {
      setLoading(() => true)
      rightFormRef.current?.setErrors({})

      try {
        await yup
          .object()
          .shape({
            cep: yup.string().required('O CEP é obrigatório'),
            uf: yup.string().required('O estado é obrigatório'),
            city: yup.string().required('A cidade é obrigatória'),
            neighborhood: yup.string().required('O bairro é obrigatório'),
            street: yup.string().required('A rua é obrigatória'),
            number: yup
              .number()
              .typeError('O campo número tem que ser um número')
              .required('O número é obrigatório'),
            complement: yup.string().notRequired(),
          })
          .validate(values, {
            abortEarly: false,
          })

        const { data } = await api.post(
          '/payments/update-address-plan',
          {
            ...values,
            idPlan: currentPlan,
          },
          {
            headers: { authorization: token },
          },
        )

        addToast({
          title: 'Sucesso!',
          description: data,
          type: 'success',
        })
        setReload((old) => !old)
      } catch (error) {
        const err = error as any
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err)
          rightFormRef.current?.setErrors(errors)
        } else if (err.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para isto',
          })
          signOut()
          history.replace('/')
        } else {
          addToast({
            type: 'error',
            title: 'OPS...',
            description: 'Ocorreu algum erro, tente novamente',
          })
        }
      } finally {
        setLoading(() => false)
      }
    },
    [addToast, history, token, currentPlan, signOut],
  )

  const handleSubmitModalForm = useCallback(
    async (values: IModalFormData): Promise<void> => {
      cardFormRef.current?.setErrors({})

      try {
        await yup
          .object()
          .shape({
            cardNumber: yup
              .string()
              .required('O número do cartão é obrigatório'),
            card_holder_name: yup
              .string()
              .required('O nome do titular é obrigatório'),
            card_expiration_date: yup
              .string()
              .required('A data de validade é obrigatória'),
            card_cvv: yup
              .string()
              .matches(/[0-9]/, 'Digite apenas números')
              .min(3, 'Digite no mínimo 3 caracters')
              .max(4, 'Digite no máximo 4 caracters')
              .required('O CVV é obrigatório'),
            cpf: yup.string().notRequired(),
            phone: yup
              .string()
              .matches(/\(\d{2}\)\s{1}\d{5}-\d{4}/, 'Digite um número válido')
              .required('O celular é obrigatório'),
            renew_payment: yup.array().notRequired(),
          })
          .validate(values, {
            abortEarly: false,
          })

        setLoading(true)

        const { card_cvv, card_expiration_date, card_holder_name, cardNumber, ...restValues } = values

        console.log({ ...restValues })

        const cardHash = await generateCardHash({
          card_cvv: card_cvv.replace(/\D/g, ''),
          card_expiration_date: card_expiration_date.replace(/\D/g, ''),
          card_holder_name: card_holder_name,
          card_number: cardNumber.replace(/\D/g, ''),
        })

        await api.post(
          '/payments/update-credit-card',
          {
            ...restValues,
            cardHash,
          },
          {
            headers: { authorization: token },
          },
        )

        addToast({
          title: 'Sucesso!',
          description: 'Dados alterados com sucesso.',
          type: 'success',
        })
        setShowModal(false)
        setReload((old) => !old)
      } catch (error) {
        const err = error as any
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err)

          cardFormRef.current?.setErrors(errors)
        } else if (err.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para isto',
          })
          signOut()
          history.replace('/')
        } else {
          addToast({
            type: 'error',
            title: 'OPS...',
            description:
              err.response?.data.message ||
              'Ocorreu algum erro, tente novamente',
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [addToast, history, token, signOut],
  )

  const handleCEP = useCallback(
    async (value: string) => {
      try {
        if (value) {
          const { data } = await api.get(
            `https://viacep.com.br/ws/${value}/json/`,
          )

          rightFormRef.current?.setData({
            uf: { value: data.uf, label: data.uf },
            city: data.localidade,
            neighborhood: data.bairro,
            street: data.logradouro,
          })
          rightFormRef.current?.setFieldValue('uf', {
            value: data.uf,
            label: data.uf,
          })
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ocorreu algum erro',
          description: 'Tente novamente',
        })
      }
    },
    [addToast],
  )

  return loading ? (
    <Loading show={loading} opacity={1} />
  ) : (
    <Main style={{ margin: '40px 0' }}>
      <Left style={{ justifyContent: 'flex-start' }}>
        <Form ref={leftFormRef} onSubmit={handleSubmitLeftForm}>
          <InputFile
            name="image"
            defaultPreview={userCurrentPlan.user?.image}
            accept="image/png,image/jpeg,image/jpg,image/webp"
          />
          <Label>Nome</Label>
          <Input
            name="name"
            placeholder="Digite o nome do titular"
            defaultValue={userCurrentPlan.user?.name}
          />
          <Label>
            CPF
            <span className="tooltip-wrapper">
              <FaInfoCircle color="#aaa" size="15" />
              <div className="tooltip">O CPF não pode ser alterado!</div>
            </span>
          </Label>
          <InputMask
            name="cpf"
            readOnly={!!userCurrentPlan.user?.cpf}
            placeholder="Digite seu CPF"
            defaultValue={userCurrentPlan.user?.cpf}
            mask="999.999.999-99"
          />
          <Label>Celular</Label>
          <InputMask
            name="phone"
            defaultValue={userCurrentPlan.user?.phone}
            // readOnly={!!userCurrentPlan.user?.phone}
            placeholder="Digite seu celular"
            mask="(99) 99999-9999"
          />
          <Label>E-mail</Label>
          <Input
            type="email"
            name="email"
            placeholder="Digite seu E-mail"
            defaultValue={userCurrentPlan.user?.email}
          />
          <Button type="submit" style={{ marginTop: 10 }} disabled={loading}>
            ATUALIZAR DADOS PESSOAIS
          </Button>
        </Form>
      </Left>
      <Right>
        <CreditCardContainer>
          <div className="credit-card">
            <button className="check" onClick={() => setShowModal(true)}>
              <FaPencilAlt color="#21a4ac" size={15} />
            </button>
            <h4>{userCurrentPlan.user?.name}</h4>
            <span>
              **** **** ****{' '}
              {userCurrentPlan.user?.payment?.card_digits || '****'}
            </span>
            <img
              src={userCurrentPlan.user?.payment?.card_brand || mastercardImage}
              alt="Bandeira do cartão de crédito"
              className="flag"
              width={30}
            />
          </div>
        </CreditCardContainer>
        <PlansContainer>
          {userCurrentPlan.plans?.map(
            (plan) =>
              plan.id === currentPlan && (
                <Plan
                  key={plan.id}
                  className="active"
                  onClick={() => setCurrentPlan(plan.id)}
                >
                  <h3>
                    {plan.title} <span>({plan.period})</span>
                  </h3>
                  <div>
                    <FaCheck color="#fff" size={15} />
                    <span>R$ {plan.value}</span>
                  </div>
                </Plan>
              ),
          )}
        </PlansContainer>
        <Button
          type="submit"
          style={{ marginTop: 10 }}
          onClick={() => history.push('/app/planos')}
        >
          Alterar plano
        </Button>
        <Form ref={rightFormRef} onSubmit={handleSubmitRightForm}>
          <Label>CEP</Label>
          <InputMask
            defaultValue={userCurrentPlan.user?.address?.cep}
            type="number"
            name="cep"
            placeholder="Digite o CEP"
            onBlur={(e) => handleCEP(e.target.value?.replace('-', ''))}
            mask="99999-999"
          />
          <div className="flex">
            <div className="item" style={{ flex: 1 }}>
              <Label>Estado</Label>
              <Select
                name="uf"
                values={states}
                placeholder="Selecione o estado"
                defaultValue={{
                  value: userCurrentPlan.user?.address?.uf,
                  label: userCurrentPlan.user?.address?.uf,
                }}
              />
            </div>
            <div className="item" style={{ marginLeft: 10 }}>
              <Label>Cidade</Label>
              <Input
                defaultValue={userCurrentPlan.user?.address?.city}
                name="city"
                placeholder="Digite a cidade"
              />
            </div>
          </div>
          <Label>Endereço</Label>
          <Input
            defaultValue={userCurrentPlan.user?.address?.street}
            name="street"
            placeholder="Digite a rua"
          />
          <div className="flex">
            <div className="item">
              <Label>Número</Label>
              <Input
                defaultValue={userCurrentPlan.user?.address?.number}
                name="number"
                placeholder="Digite o número"
                type="number"
              />
            </div>
            <div className="item" style={{ marginLeft: 10 }}>
              <Label>Bairro</Label>
              <Input
                defaultValue={userCurrentPlan.user?.address?.neighborhood}
                name="neighborhood"
                placeholder="Digite o bairro"
              />
            </div>
          </div>
          <Label>Complemento</Label>
          <Input
            defaultValue={userCurrentPlan.user?.address?.complement}
            name="complement"
            placeholder="Digite o complemento"
          />
          <Button type="submit" style={{ marginTop: 10 }} disabled={loading}>
            ATUALIZAR DADOS DE COBRANÇA
          </Button>
        </Form>
      </Right>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Form
          ref={cardFormRef}
          onSubmit={(values) => handleSubmitModalForm(values)}
          style={{ width: '100%' }}
        >
          <h2>Atualizar os dados do cartão</h2>
          <Label>Nome do titular do cartão</Label>
          <Input
            name="card_holder_name"
            placeholder="Digite o nome do titular do cartão"
          />
          <Label>CPF</Label>
          <InputMask
            name="cpf"
            defaultValue={userCurrentPlan.user?.cpf}
            readOnly={!!userCurrentPlan.user?.cpf}
            placeholder="Digite seu CPF"
            mask="999.999.999-99"
          />
          <Label>Celular</Label>
          <InputMask
            name="phone"
            defaultValue={userCurrentPlan.user?.phone}
            readOnly={!!userCurrentPlan.user?.phone}
            placeholder="Digite seu celular"
            mask="(99) 99999-9999"
          />
          <Label>Número do cartão</Label>
          <InputMask
            name="cardNumber"
            placeholder="Digite o número do cartão"
            mask="9999 9999 9999 9999"
          />
          <div className="flex">
            <div className="item">
              <Label>Validade</Label>
              <InputMask
                name="card_expiration_date"
                placeholder="Data de validade"
                mask="99/99"
              />
            </div>
            <div className="item" style={{ marginLeft: 10 }}>
              <Label>CVV</Label>
              <InputMask name="card_cvv" placeholder="CVV" mask="9999" />
            </div>
          </div>
          <InputCheck
            type="checkbox"
            name="renew_payment"
            options={[{ id: '1', label: 'Renovação automática', value: 'yes' }]}
          />
          <div className="flex">
            <div className="item">
              <Button
                type="button"
                style={{
                  marginTop: 10,
                  width: '90%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setShowModal(false)
                }}
              >
                CANCELAR
              </Button>
            </div>
            <div className="item">
              <Button
                type="submit"
                style={{
                  marginTop: 10,
                  width: '90%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                disabled={loading}
              >
                CONFIRMAR
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </Main>
  )
}

export default Profile
