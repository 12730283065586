import styled from 'styled-components'
import { Form as Unform } from '@unform/web'

export const Main = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #ddd;

@media (max-width: 700px) {
  border: none;
  border-top: 2px solid #ddd;
  margin-top: 30px;
  padding-top: 30px;
}
`

export const PlansContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  width: 100%;
`

export const Plan = styled.button`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 2px solid #21a4ac;
  border-radius: 8px;
  background: #fff;
  min-width: 150px;
  max-width: 150px;
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  cursor: pointer;

  h3 {
    margin: 0;
    color: #21a4ac;
    text-transform: uppercase;
    width: 100%;
  }
  
  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
    width: 100%;

    span {
      color: #21a4ac;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  :hover {
    background-color: #21a4ac;

    h3, span {
      color: #fff;
    }
  }

  &.active {
    background-color: #21a4ac;
    cursor: default;

    h3, span {
      color: #fff;
    }
  }
`

export const CreditCardContainer = styled.div`
  margin-bottom: 3rem;

  .credit-card {
    position: relative;
    border: 2px solid #21a4ac;
    border-radius: 8px;
    padding: 1.5rem 1.5rem 1.5rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #21a4ac;

    h4 {
      margin: 0 0 0.5rem;
      text-align: end;
    }

    span {
      text-align: end;
    }

    .check {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      padding: 0;
      margin: 0;
      border: 0;
      background: 0;
      cursor: pointer;
      transition: transform 400ms ease-in-out;

      :hover {
        transform: rotate(-360deg);
      }
    }

    .flag {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
    }
  }
`

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 90%;
  max-width: 500px;

  h2 {
    color: #333;
    text-align: center;
    width: 100%;
  }

.flex {
  display: flex;
  justify-content: space-between;

  .item {
    flex: 1;
  }
}
`

export const Label = styled.span`
  font-weight: 600;
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
`
