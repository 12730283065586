import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { AuthProvider } from './Auth'
import { ToastProvider } from './Toast'
import { VimeoProvider } from './Vimeo'
import { LoginProvider } from './Login'
import { store } from 'store'

const AppProvider: React.FC = ({ children }) => (
  <ReduxProvider store={store}>
    <AuthProvider>
      <ToastProvider>
        <VimeoProvider>
          <LoginProvider>{children}</LoginProvider>
        </VimeoProvider>
      </ToastProvider>
    </AuthProvider>
  </ReduxProvider>
)

export default AppProvider
