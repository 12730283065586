import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'

function setupStore(preloadedState?: any) {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
  })
}

export const store = setupStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
