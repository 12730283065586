import React from 'react'
import { Container } from './styles'
import LoadingSVG from '../../assets/loader-hp.gif'

interface IProps {
  show?: boolean
  opacity?: number
}

const Loading: React.FC<IProps> = ({ show = false, opacity = 0.8 }) => {
  return (
    <Container style={{ display: show ? 'block' : 'none', opacity }}>
      <img
        src={LoadingSVG}
        alt="Loading..."
        style={{ height: '10%', animation: '2s spin infinite' }}
      />
    </Container>
  )
}

export default Loading
