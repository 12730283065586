import React, { useEffect, useRef, InputHTMLAttributes } from 'react'
import { useField } from '@unform/core'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    value: string;
    label: string;
    link?: {
      url: string;
      text: string;
    }
  }[];
}

const CheckboxInput: React.FC<Props> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField, defaultValue = [], error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter(ref => ref.checked).map(ref => ref.value)
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true
          }
        })
      }
    })
  }, [defaultValue, fieldName, registerField])

  return (
    <>
      <div>
      {options.map((option, index) => (
        <label htmlFor={option.id} key={option.id} style={{ color: '#333' }}>
        <input
          defaultChecked={defaultValue.find((dv: string) => dv === option.id)}
          ref={ref => {
            inputRefs.current[index] = ref as HTMLInputElement
          }}
          value={option.value}
          type="checkbox"
          id={option.id}
          style={{ marginRight: 5 }}
          {...rest}
        />
        {option.label} {option.link && <a href={option.link.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#21a4ac' }}>{option.link.text}</a>}
        </label>
      ))}
      </div>
      {error && (
        <small style={{ color: '#fa4545' }}>{error}</small>
      )}
    </>
  )
}

export default CheckboxInput
