import { ICourseTypes } from '@types'
import React from 'react'
// import ReactPlayer from 'react-player'

import { Container, Title, Wrapper } from './styles'

function getTypeText(type: ICourseTypes): string {
  switch (type) {
    case 'artigo':
      return 'Laboratório de Artigos'
    case 'aula':
      return 'Biblioteca de Atualização'
    case 'curso':
      return 'Cursos de Certificação'
    default:
      return '-'
  }
}

const HeaderList: React.FC<{ type: ICourseTypes }> = ({ type }) => {
  const title = getTypeText(type)
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
      </Container>
    </Wrapper>
  )
}

export default HeaderList
