import React from 'react'
import { withRouter } from 'react-router'
import { Container, Wrapper, Line, Dot1, Dot3 } from './styles'

const BreadCrumbCheckout = withRouter(({ location: { pathname } }: any) => {
  return (
    <Container>
      {/* {!pathname.includes('selecione-o-plano') && (
        <button
          className="back"
          onClick={() => {
            if (pathname.includes('cadastro')) {
              localStorage.removeItem('@HealthPlay:PricingCard')
              localStorage.removeItem('@HealthPlay:PricingInfo')
              history.push('/app/planos/selecione-o-plano')
            }
            if (pathname.includes('finalizacao')) {
              localStorage.removeItem('@HealthPlay:PricingCard')
              localStorage.removeItem('@HealthPlay:PricingInfo')
              history.push('/app/planos/cadastro-dos-dados')
            }
          }}
        >
          <FaChevronLeft size={30} />
        </button>
      )} */}
      <Wrapper>
        <Line />

        <Dot1 className="dot" active={true}>
          <span>Informações de compra</span>
        </Dot1>
        <Dot3 className="dot" active={pathname.includes('finalizacao')}>
          <span>Confirmação</span>
        </Dot3>
      </Wrapper>
    </Container>
  )
})

export default BreadCrumbCheckout
