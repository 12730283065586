import Button from 'components/Button'
import React, { useCallback, useRef, useState } from 'react'
import GoogleLogin from 'react-google-login'
import * as yup from 'yup'
import { ReactComponent as Logo } from '../../assets/icon_logo.svg'
import { ReactComponent as GoogleLogo } from '../../assets/Google-G.svg'
import {
  Container,
  Form,
  Label,
  Input,
  Left,
  Right,
  SocialContainer,
  ModalContainer,
  RightContainer,
} from './styles'
import CheckboxInput from 'components/Checkbox'
import getValidationErrors from 'utils/getValidationErrors'
import { FormHandles } from '@unform/core'
import { useToast } from 'hook/Toast'
import Modal from 'components/Modal'
import { useHistory } from 'react-router'
import { useAuth } from 'hook/Auth'

interface IFormData {
  name: string
  email: string
  password: string
  confirmPassword: string
  terms: string[]
}

const Register: React.FC = () => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const { addToast } = useToast()
  const { signUp, signUpSocialMedia } = useAuth()
  const history = useHistory()

  const handleSubmit = useCallback(
    async ({
      name,
      email,
      password,
      confirmPassword,
      terms,
    }: IFormData): Promise<void> => {
      setLoading(() => true)
      formRef.current?.setErrors({})
      try {
        await yup
          .object()
          .shape({
            name: yup.string().required('O nome é obrigatório'),
            email: yup
              .string()
              .email('Digite um e-mail válido')
              .required('O e-mail é obrigatório'),
            password: yup
              .string()
              .min(6, 'A senha deve ter no mínimo 6 caracteres')
              .required('A senha é obrigatória'),
            confirmPassword: yup
              .string()
              .oneOf([yup.ref('password'), ''], 'A senhas devem ser iguais'),
            terms: yup
              .array()
              .of(yup.string())
              .compact()
              .required('Aceite os termos de uso'),
          })
          .validate(
            {
              name,
              email,
              password,
              confirmPassword,
              terms,
            },
            {
              abortEarly: false,
            },
          )

        await signUp({
          name,
          email,
          password,
          confirmPassword,
        })

        addToast({
          type: 'success',
          title: 'Registrado com sucesso',
        })
        history.push('/app/planos')
        // setShow(true)
      } catch (error) {
        const err = error as any
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        } else if (err.response?.status === 400) {
          addToast({
            type: 'error',
            title: err.response.data.message,
          })
        } else if (err.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para isto',
          })
        } else {
          addToast({
            type: 'error',
            title: 'OPS...',
            description: 'Ocorreu algum erro, tente novamente',
          })
        }
      } finally {
        setLoading(() => false)
      }
    },
    [addToast, signUp, history],
  )

  const handleSubmitSocial = useCallback(
    async (response) => {
      setLoading(() => true)
      try {
        const info =
          response.loginType === 'Facebook'
            ? {
                ...response,
                userId: response.userID,
                userToken: response.accessToken,
              }
            : {
                name: response.profileObj.name,
                email: response.profileObj.email,
                photo: response.profileObj.imageUrl,
                userId: response.googleId,
                userToken: response.tokenId,
                loginType: 'Google',
              }

        if (await signUpSocialMedia(info)) history.push('/app/planos')
      } catch (err) {
        addToast({
          type: 'error',
          title: 'OPS...',
          description: 'Ocorreu algum erro, tente novamente',
        })
      } finally {
        setLoading(() => false)
      }
    },
    [addToast, signUpSocialMedia, history],
  )

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        Cadastre-se usando suas redes sociais ou seu e-mail
      </h3>
      <Container>
        <Left>
          <SocialContainer>
            <Logo height={30} style={{ marginTop: 20 }} />

            <h2 style={{ textAlign: 'center', marginTop: 40, marginBottom: 0 }}>
              Plano Mensal
            </h2>
            <h1 style={{ fontSize: 42, textAlign: 'center', marginTop: 5 }}>
              R$ 79,90
            </h1>
            <p style={{ fontSize: 12, marginTop: 0 }}>
              {' '}
              ou R$ 799,00 plano anual
            </p>
            <p style={{ fontSize: 12, color: ' #929292' }}>
              Faça seu cadastro para adquirir o melhor plano para você!
            </p>
            {/* <GoogleLogin
              clientId="339761781834-g89574acjougioadcp56po6urad2mm43.apps.googleusercontent.com"
              onSuccess={(response) =>
                handleSubmitSocial({ ...response, loginType: 'Google' })
              }
              cookiePolicy={'single_host_origin'}
              render={({ onClick }) => (
                <button onClick={onClick}>
                  <GoogleLogo className="google" />
                  <span>Cadastrar com o Google</span>
                </button>
              )}
            /> */}
            {/* <FacebookLogin
              containerStyle={{ marginTop: 10 }}
              cssClass="fb-container"
              textButton="Cadastrar com o Facebook"
              appId="766044517659795"
              fields="name,email,picture"
              callback={(response) =>
                handleSubmitSocial({ ...response, loginType: 'Facebook' })
              }
              icon={<FaFacebookF className="facebook" />}
            /> */}
          </SocialContainer>
        </Left>
        <Right>
          <RightContainer>
            <GoogleLogin
              clientId="339761781834-g89574acjougioadcp56po6urad2mm43.apps.googleusercontent.com"
              onSuccess={(response) =>
                handleSubmitSocial({ ...response, loginType: 'Google' })
              }
              cookiePolicy={'single_host_origin'}
              render={({ onClick }) => (
                <button onClick={onClick}>
                  <GoogleLogo className="google" />
                  <span>Cadastrar com o Google</span>
                </button>
              )}
            />
          </RightContainer>
          <div style={{ color: 'darkgray' }}>
            <p style={{ display: 'flex', width: 100 }}>
              <hr style={{ width: 40, marginRight: 10, opacity: '0.5' }} />
              ou
              <hr style={{ width: 40, marginLeft: 10, opacity: '0.5' }} />
            </p>
          </div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Label>Nome Completo</Label>
            <Input name="name" placeholder="Digite o seu nome completo" />
            <Label>E-mail</Label>
            <Input name="email" placeholder="Digite o seu e-mail" />
            <Label>Senha</Label>
            <Input
              name="password"
              placeholder="Digite a sua senha"
              type="password"
            />
            <Label>Confirmar senha</Label>
            <Input
              name="confirmPassword"
              placeholder="Digite novamente a sua senha"
              type="password"
            />
            <CheckboxInput
              name="terms"
              options={[
                {
                  id: '1',
                  label: 'Aceito os',
                  value: '1',
                  link: { text: 'Termos de uso', url: '/app/termos-de-uso' },
                },
              ]}
            />
            <Button type="submit" style={{ marginTop: 20 }} disabled={loading}>
              REALIZAR CADASTRO
            </Button>
          </Form>
        </Right>
      </Container>
      {show && (
        <Modal isOpen={show} onClose={() => setShow(false)}>
          <ModalContainer>
            <Logo />
            <h2>Tudo pronto!</h2>
            <p>
              {/* Você já pode acessar nossos vídeos gratuitos. */}
              <br />
            </p>
            <div>
              <button
                className="white-btn"
                onClick={() => history.push('/app')}
              >
                {/* Ir para o conteúdo gratuito */}
              </button>
              <button
                className="blue-btn"
                onClick={() => history.push('/app/planos')}
              >
                Conhecer planos
              </button>
            </div>
          </ModalContainer>
        </Modal>
      )}
    </div>
  )
}

export default Register
