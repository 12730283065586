import styled from 'styled-components'
import Button from 'components/Button/'
// import { primaryColor } from 'styles/colors'

export const Wrapper = styled.div`
  width: 100%;
  background-size: 100% 120%;
  background-image: url('https://parsefiles.back4app.com/usefnjCg8mRvAJDAz9nNAYsbAzmA0z7XsWpyrcDO/a2839da7eb8de1243b73171110df5f6e_view-691643_1920.jpg');
`

export const Container = styled.div`
  width: 100%;
  height: 400px;
  background-color: #292929;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 24px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    background-size: 120% 100%;
    height: 100%;
  }

  @media (max-width: 650) {
    flex-direction: column;
    align-items: flex-start;
    background-size: 120% 100%;
    height: 100px;
  }
`
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;

  @media screen and (max-width: 1000px) {
    align-items: center;
    margin-bottom: 40px;
  }
`
export const Icon = styled.img`
  width: 80px;
  @media (max-width: 600px) {
    display: none;
  }
`
export const TextContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.div`
  font-size: 2.8rem;
  font-weight: bold;
  color: white;
  margin-bottom: 8px;
  width: 80%;

  @media screen and (max-width: 1000px) {
    font-size: 2.2rem;
    font-weight: bold;
    color: white;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
    align-items: center;
  }
`
export const Description = styled.div`
  font-size: 14px;
  color: white;
`
export const EnrollButton = styled(Button)`
  margin-top: 2rem;
  height: 42px;
  max-width: 200px;
  background-color: #fff;
  color: #292929;
  font-weight: 800;

  :hover {
    background-color: #292929;
    color: #fff;
    border: 2px solid #fff;
  }
`
