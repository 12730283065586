import React from 'react'
import ReactPlayer from 'react-player'

import {
  Container,
  // ItemContainer,
  // EnrollButton,
  // Title,
  Wrapper,
} from './styles'

// const onClick = () => {
//   window.scroll({
//     top: document.body.offsetHeight,
//     left: 0,
//     behavior: 'smooth',
//   })
// }

const HeaderList: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <ReactPlayer
          url="https://vimeo.com/375798199"
          style={{ maxWidth: 640, maxHeight: 360 }}
          width="100%"
          height="100%"
          controls={true}
        />
      </Container>
    </Wrapper>
  )
}

export default HeaderList
