import styled from 'styled-components'

import {
  Slider as RSlider,
  ButtonBack as RButtonBack,
  ButtonNext as RButtonNext,
} from 'pure-react-carousel'

import CourseItem from './CourseItem/'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  position: relative;
`

export const CoursesContainer = styled.div`
  display: flex;
`

export const SingleCourse = styled(CourseItem)`
  padding: 0 32px;
  height: 100%;
  max-height: 320px;
`

export const Slider = styled(RSlider)`
  max-height: 280px;
`

export const ButtonBack = styled(RButtonBack)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  height: 55px;
  width: 55px;
  background: #21a4ac;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 11%);
  svg {
    color: #fff;
  }

  :disabled {
    cursor: default;
    display: none;
    svg {
      color: #ddd;
    }
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: 475px) {
    left: -15px;
    top: 48%;
  }
`

export const ButtonNext = styled(RButtonNext)`
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #21a4ac;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 11%);
  svg {
    color: #fff;
  }

  :disabled {
    cursor: default;
    display: none;

    svg {
      color: #ddd;
    }
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: 475px) {
    right: -15px;
    top: 48%;
  }
`

export const SeeAll = styled(Link)`
  color: #21a4ac;
  float: right;
`
