import styled from 'styled-components'

interface LevelIconProps {
  readonly active: boolean
}

export const Container = styled.div`
  /* min-height: 300px; */
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .video {
    grid-area: 'video';
  }

  .info {
    grid-area: 'info';
    display: flex;
    flex-direction: column;
    width: 100%;

    h5 {
      color: #292929;
      font-size: 1rem;
      font-weight: 800;
      margin: 8px 0 2px 8px;
      text-decoration: none;
      max-width: 310px;
    }

    h6 {
      color: #8c8c8c;
      font-size: 0.9rem;
      margin-left: 8px;
      display: flex;
      align-items: center;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    span {
      color: #8c8c8c;
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-top: 10px;
      opacity: 0.5;
    }

    p {
      max-width: 400px;
      margin: 0;
      margin-top: 10px;
      font-size: 1rem;
      color: #292929;

      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .bottom-title {
    grid-area: 'title';
  }

  @media screen and (max-width: 900px) {
    &.vertical {
      flex-direction: column;

      .info {
        margin-left: 5px;
        margin-top: 15px;
      }

      p {
        max-width: none;
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;

    .info {
      margin-left: 0;
    }

    p {
      max-width: none;
    }
  }
`

export const LevelContainer = styled.div`
  display: flex;
`

export const LevelIcon = styled.img.attrs<LevelIconProps>(
  (props: LevelIconProps) => ({
    src: props.active
      ? '/assets/icons/favorite_full.png'
      : '/assets/icons/favorite_cinza.png',
  }),
)<LevelIconProps>`
  height: 20px;
  margin: 0 2px;
`

export const CourseTitle = styled.h4`
  color: #21a4ac;
  font-size: 18px;
  margin-left: 8px;
  margin-bottom: 8px;
`
export const CourseDetails = styled.div`
  color: #21a4ac;
  font-size: 14px;
  margin-left: 8px;
  display: flex;
  align-items: center;
`
