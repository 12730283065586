/* eslint-disable indent */
import React from 'react'
import { useLocation } from 'react-router-dom'

// import Footer from './Footer'
// import Header from './Header'
import { Container } from './styles'

const Layout: React.FC = ({ children }) => {
  const navigation = useLocation()

  return navigation.pathname === '/' ||
    navigation.pathname.includes('termos-de-uso') ||
    navigation.pathname.includes('politica-de-privacidade') ? (
    <Container className="layout">{children}</Container>
  ) : (
    <Container className="layout">
      {/* <Header /> */}
      <main>{children}</main>
      {/* <Footer /> */}
    </Container>
  )
}

export default Layout
