import styled from 'styled-components'
import Button from 'components/Button/'
import { primaryColor } from 'styles/colors'
const responsiveTrigger = 1015

export const Container = styled.div`
  display: flex;

  @media (max-width: ${responsiveTrigger}px) {
    flex-direction: column;
  }
`
export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.5;

  div.views-likes {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    span {
      display: flex;
      align-items: center;
      color: ${primaryColor};
      font-weight: 600;
      padding: 5px 10px;

      svg {
        margin-right: 5px;
      }
    }

    button {
      cursor: pointer;
      padding: 10px;
      border: 2px solid ${primaryColor};
      color: ${primaryColor};
      background: transparent;
      border-radius: 5px;
      transition: all 0.4s;

      :hover {
        background: ${primaryColor};
        color: #fff;
      }

      :focus {
        outline: none;
      }
    }

    @media (max-width: 460px) {
      font-size: 12px;
    }
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding: 0 32px;
  @media (max-width: ${responsiveTrigger}px) {
    margin-top: 24px;
    padding: 0;
  }
`
export const StartButton = styled(Button)`
  align-self: flex-start;
  border-radius: 5px;
  font-weight: 500;
  margin-top: 16px;
`
