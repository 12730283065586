import React, { useEffect } from 'react'
import { testCardHashGeneration } from 'services/pagarme'

const TestCardHash: React.FC = () => {
  useEffect(() => {
    testCardHashGeneration()
  }, [])

  return <div>Look Console</div>
}
export default TestCardHash
