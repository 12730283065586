import React, { useEffect, useRef } from 'react'
import ReactInputMask, { Props as InputProps } from 'react-input-mask'
import { useField } from '@unform/core'
import { Container } from './styles'

interface IProps extends InputProps {
  name: string
}

const InputMask: React.FC<IProps> = ({ name, type, ...rest }) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value)
      },
      clearValue(ref: any) {
        ref.setInputValue('')
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      <Container>
        <ReactInputMask ref={inputRef} defaultValue={defaultValue} {...rest} />
      </Container>
      {error && <small style={{ color: '#fa4545' }}>{error}</small>}
    </>
  )
}

export default InputMask
