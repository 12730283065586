import React from 'react'
import { AiOutlinePlayCircle } from 'react-icons/ai'
import { IVideo } from '@types'
import { Container, VideoItem } from './styles'
import { useVideo } from 'hook/Vimeo'
// import { useHistory } from 'react-router'

interface IVideoListProps {
  clinicId?: string
  videos: IVideo[]
  duration: string
  progress: number
  hasCertificate?: boolean
}

const VideoList: React.FC<IVideoListProps> = ({
  clinicId,
  duration,
  videos,
  progress,
  hasCertificate,
}) => {
  const { setVideo, id: videoId } = useVideo()

  // const history = useHistory()

  return (
    <Container>
      <h3>Tempo Total de Aula ( {duration} min )</h3>
      <ol>
        {videos?.map((v) => (
          <VideoItem
            key={v.id}
            active={v.id === videoId}
            onClick={() => {
              setVideo({
                type: v.link ? 'link' : 'thumb',
                url: v.link || v.thumbnail,
                id: v.id,
              })
              window.location.reload()
            }}
          >
            <div className="name">
              <AiOutlinePlayCircle />
              <li>{v.name}</li>
            </div>
            <span className="duration">{v.duration} min</span>
          </VideoItem>
        ))}
      </ol>
      {/* <ProgressContainer>
        <h3>Seu progresso</h3>
        <ProgressBarContainer>
          <ProgressBar percent={progress} />
        </ProgressBarContainer>
        <ProgressNumber>{progress}% concluido</ProgressNumber>
        {progress >= 100 && !hasCertificate && (
          <button
            type="button"
            onClick={() =>
              history.push(clinicId ? `/app/clinica/prova/${clinicId}` : '/app')
            }
          >
            Fazer prova
          </button>
        )}
      </ProgressContainer> */}
    </Container>
  )
}

export default VideoList
