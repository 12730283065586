import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Section, SectionTitle } from 'styles/'
import VideoContent from 'components/VideoContent'
// import CourseLessonsList from 'components/CourseLessonsList'

import Loading from 'components/Loading'
import {
  Container,
  ContainerLeft,
  ContainerRigth,
  RateContainer,
  Review,
  ReviewsContainer,
  TabButton,
  TabsButtonContainer,
  TeacherInfo,
} from './styles'
// import starImage from '../../assets/favorite_full.png'
import { useAuth, IUserProgress } from 'hook/Auth'
import { useVideo } from 'hook/Vimeo'
import { useToast } from 'hook/Toast'
import VideoList from 'components/VideoList'
import { ICourseData, ICourseTypes } from '@types'
import api from 'services/api'
import starImage from 'assets/favorite_full.png'
import ModalReview from 'components/ModalReview'
// import BuyCourse from 'components/BuyCourse'

const Detail: React.FC<{ type: ICourseTypes }> = ({ type }) => {
  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [show, setShow] = useState(false)
  const [completeVideo, setCompleteVideo] = useState(false)
  const [resize, setResize] = useState(window.innerWidth)
  const [tab, setTab] = useState<'geral' | 'professor' | 'reviews'>('geral')
  const [data, setData] = useState<ICourseData>({} as any)
  const { slug } = useParams<{ slug: string }>()

  const { user, token, setUserProgress, userProgress } = useAuth()
  const { setVideo, id: videoId, type: videoType, url: videoUrl } = useVideo()
  const { addToast } = useToast()
  const history = useHistory()

  useEffect(() => {
    window.addEventListener('resize', (e) => setResize(window.innerWidth))
    return () =>
      window.removeEventListener('resize', (e) => setResize(window.innerWidth))
  }, [])

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        console.log('LOADING DATA')
        const { data: resData } = await api.get<ICourseData>(
          `/${type}s/${slug}`,
          {
            headers: token ? { authorization: token } : {},
          },
        )
        setData(resData)

        const isThisVideo = resData.videos?.find((v: any) => v.id === videoId)
        setVideo(
          isThisVideo
            ? {
                type: isThisVideo.link ? 'link' : 'thumb',
                url: isThisVideo.link || resData.image,
                id: isThisVideo.id,
              }
            : {
                type: resData.videos[0].link ? 'link' : 'thumb',
                url: resData.videos[0].link || resData.image,
                id: resData.videos[0].id,
              },
        )
        // }
      } catch (error) {
        const err = error as any
        addToast({
          title: 'Ops...',
          description: err.response?.data?.message?.replace('Ops... ', ''),
          type: 'error',
        })
        history.push('/app')
      } finally {
        setLoading(false)
      }
    }

    loadData()
    // eslint-disable-next-line
  }, [slug, user, token, videoType, videoUrl, reload, type, videoId])

  useEffect(() => {
    setCompleteVideo(false)
  }, [slug])

  useEffect(() => {
    async function saveCompletedVideo() {
      try {
        if (!token) return Promise.resolve()
        const { data: resData } = await api.post<{
          userProgress: IUserProgress[]
        }>(
          '/user/complete-video',
          {
            type: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
            idCurso: data.id,
            idVideo: videoId,
          },
          {
            headers: token ? { authorization: token } : {},
          },
        )

        setUserProgress(resData.userProgress)
      } catch (err) {
        addToast({
          title: 'Ops...',
          description: 'Ocorreu algum erro, tente novamente mais tarde',
          type: 'error',
        })
        history.push('/app')
      }
    }

    if (completeVideo) {
      saveCompletedVideo()
      // setCompleteVideo(false)
    }
    // eslint-disable-next-line
  }, [completeVideo])

  return (
    <Container>
      <ContainerLeft>
        <Section style={{ borderBottom: '1px solid #21a4ac' }}>
          <SectionTitle
            style={{ alignSelf: 'flex-start', marginBottom: 10, color: '#333' }}
          >
            {data.name}
          </SectionTitle>
          <RateContainer>
            {/* {user ? ( */}
            <span style={{ fontWeight: 400 }}>
              Professor - {data.teacher?.name}{' '}
              {/* {type === 'course' && data.gratuito ? ' | Curso Gratuito' : null} */}
            </span>
            {/* ) : (
              <>
                <img src={starImage} alt="Nota" />
                <span>{data.rate}</span>
              </>
            )} */}
          </RateContainer>
          {data && (
            <VideoContent
              professor={data.teacher?.name}
              duration={data.duration}
              description={data.short_description}
              onTimeUpdate={(event) => {
                if (event.percent > 0.95) setCompleteVideo(true)
              }}
              // views={data.views}
              // likes={data.likes}
            />
          )}
          {/* add params: price, expiresAt, path  */}
          {/* {type === 'course' && !data.userHasAccess && (
            <BuyCourse
              course={data}
              price={data.price!}
              expiresAt={data.expiration_in_days!}
            />
          )} */}
          <TabsButtonContainer>
            <TabButton active={tab === 'geral'} onClick={() => setTab('geral')}>
              Geral
            </TabButton>
            <TabButton
              active={tab === 'professor'}
              onClick={() => setTab('professor')}
            >
              Professor
            </TabButton>
            <TabButton
              active={tab === 'reviews'}
              onClick={() => setTab('reviews')}
            >
              Avaliações
            </TabButton>
          </TabsButtonContainer>
        </Section>
        <Section>
          {tab === 'geral' && (
            <>
              <h2 style={{ fontWeight: 800 }}>Sobre {data.name}</h2>
              <p style={{ fontWeight: 300, lineHeight: '1.4rem' }}>
                {data.long_description}
              </p>
            </>
          )}
          {tab === 'professor' && (
            <TeacherInfo>
              <div className="info">
                <img src={data.teacher.image} alt={data.teacher.name} />
                <div>
                  <h2 style={{ fontWeight: 800 }}>{data.teacher.name}</h2>
                  <h3 style={{ fontWeight: 300 }}>{data.teacher.occupation}</h3>
                </div>
              </div>
              <p style={{ fontWeight: 300, lineHeight: '1.4rem' }}>
                {data.teacher.description}
              </p>
            </TeacherInfo>
          )}
          {tab === 'reviews' && (
            <ReviewsContainer>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ fontWeight: 800 }}>Avaliações e comentários</h2>
                {/* {user && type !== 'course' && ( */}
                <button className="review-button" onClick={() => setShow(true)}>
                  AVALIAR
                </button>
                {/* )} */}
              </div>
              {data.reviews?.length > 0 ? (
                data.reviews.map((r) => {
                  const stars = Array.from(new Array(r.stars))
                  return (
                    <Review key={r.id}>
                      <div className="infos">
                        <img src={r.user.image} alt={r.user.name} />
                        <div>
                          <h4>{r.user.name}</h4>
                          <small>
                            {new Intl.DateTimeFormat('pt-BR').format(
                              new Date(r.createdAt),
                            )}
                          </small>
                        </div>
                        <div style={{ display: 'flex' }}>
                          {stars.map((_, i) => (
                            <img
                              key={i}
                              src={starImage}
                              alt="Estrela"
                              style={{ width: 30, height: 30 }}
                            />
                          ))}
                        </div>
                      </div>
                      <p>{r.text}</p>
                    </Review>
                  )
                })
              ) : (
                <h3>Ainda não há avaliações</h3>
              )}
            </ReviewsContainer>
          )}
        </Section>
        {/* {!user ? (
          <Section>
            <CourseAreaTitle>Conteúdo Programático</CourseAreaTitle>
            <CourseLessonsList lessons={data.lessons} />
          </Section>
        ) :  */}
        {resize < 1015 && (
          <VideoList
            clinicId={type === 'clinica' ? data.id : undefined}
            videos={data.videos}
            duration={data.duration}
            progress={
              userProgress?.find((v) => v.objectId === data.id)?.progress || 0
            }
            hasCertificate={data.hasCertificate}
          />
        )}
      </ContainerLeft>
      {resize >= 1015 && (
        <ContainerRigth>
          <VideoList
            clinicId={type === 'clinica' ? data.id : undefined}
            videos={data.videos}
            duration={data.duration}
            progress={
              userProgress?.find((v) => v.objectId === data.id)?.progress || 0
            }
            hasCertificate={data.hasCertificate}
          />
        </ContainerRigth>
      )}
      {loading && <Loading show={loading} />}
      {show && (
        <ModalReview
          show={show}
          onClose={() => setShow(false)}
          reload={() => setReload((old) => !old)}
          courseId={data.id}
          type={type}
          courseName={data.name}
          teacher={data.teacher}
        />
      )}
    </Container>
  )
}

export default Detail
