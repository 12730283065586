import React from 'react'
import Logo from 'components/Logo'
import {
  Container,
  FooterItem,
  FooterWrapper,
  SocialMediaIcon,
  ContactIcon,
} from './styles'

export default function Footer() {
  return (
    <Container>
      <FooterWrapper>
        <FooterItem
          style={{ flex: 1, marginRight: 40, flexDirection: 'column' }}
        >
          <Logo color="white" />
          <br />
        </FooterItem>

        <FooterItem
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <CleanLink href="/app/termos-de-uso" target="_blank">
            Termos de Uso
          </CleanLink>
          <CleanLink href="/app/politica-de-privacidade" target="_blank">
            Política de Privacidade
          </CleanLink> */}
        </FooterItem>

        <FooterItem
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FooterItem style={{ fontSize: 14, color: 'white' }}>
            Fale Conosco
          </FooterItem>
          <FooterItem style={{ flex: 1 }}>
            <a
              href="https://wa.me/5551997982266"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ContactIcon src="/assets/icons/icon_phone.png" />
            </a>
            <a
              href="mailto: contato@healthplay.pro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ContactIcon src="/assets/icons/icon_mail2.png" />
            </a>
          </FooterItem>
          {/* <Button theme="tertiary" className="mv2">
          FALE CONOSCO
        </Button> */}
        </FooterItem>
        <FooterItem style={{ flex: 1 }}>
          <a
            href="https://www.facebook.com/healthplay.pro"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialMediaIcon src="/assets/icons/facebook.png" />
          </a>
          <a
            href="https://www.instagram.com/healthplaypro"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialMediaIcon src="/assets/icons/instagram.png" />
          </a>
          <a
            href="https://www.linkedin.com/company/performance-ensino-digital-em-exerc%C3%ADcio-sa%C3%BAde/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialMediaIcon src="/assets/icons/linkedin.png" />
          </a>
        </FooterItem>
      </FooterWrapper>
      <FooterWrapper>
        <p style={{ color: '#fffc', flexDirection: 'column', marginTop: 30 }}>
          © Copyright HealthPlay. Todos direitos reservados 2024.
        </p>
      </FooterWrapper>
    </Container>
  )
}
