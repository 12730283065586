import styled from 'styled-components'
import { primaryColor } from 'styles/colors'

export const Container = styled.div`
  display: flex;
`

export const ContainerLeft = styled.div`
  flex: 3;
`

export const ContainerRigth = styled.aside`
  flex: 1;
`

export const RateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    height: 20px;
    width: 20px;
  }

  span {
    color: ${primaryColor};
    margin-left: 5px;
    font-weight: 700;
  }
`

export const TabsButtonContainer = styled.div`
  display: flex;
  margin: 40px 0 0;
`

export const TabButton = styled.button<{ active?: boolean }>`
  cursor: pointer;
  width: fit-content;
  border: none;
  background: transparent;
  color: ${primaryColor};
  padding-bottom: 2px;
  margin: 0 10px;
  border-bottom: ${({ active }) => active ? `3px solid ${primaryColor}` : 'none'};
  transition: border 0.1s;

  :hover {
    border-bottom: 3px solid ${primaryColor};
  }

  :focus {
    outline: none;
  }
`

export const TeacherInfo = styled.div`
  .info {
    display: flex;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 20px;
    }
  }

  h2 {
    margin-bottom: 5px;
  }

  h3 {
    color: #5f5f5f;
    margin-top: 0;
  }
`

export const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;

  button.review-button {
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #21a4ac;
    color: white;
    border: 1px solid #21a4ac;
    margin-left: 20px;
  }
`

export const Review = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;

  .infos {
    display: flex;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }

    h4 {
      margin: 0 0 5px;
      color: ${primaryColor};
    }

    div {
      margin-left: 10px;
      padding: auto 0;
    }
  }
`
