import styled from 'styled-components'
// import Button from 'components/Button/'

export const Wrapper = styled.div`
  width: 100%;
  background-size: 100%;
  /* background-image: url('https://parsefiles.back4app.com/usefnjCg8mRvAJDAz9nNAYsbAzmA0z7XsWpyrcDO/d89b2ec83e757e563da914fc97c183f7_man-2604149_1280.jpg'); */
  background-size: cover;
`

export const Container = styled.div`
  width: 100%;
  height: 250px;
  background: linear-gradient(200.86deg, #27d2d9 -0.06%, #1d7a7d 102.31%),
    #29a5aa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    background-size: 120% 100%;
    height: 200px;
  }
`
export const Title = styled.div`
  font-size: 42px;
  font-weight: bold;
  color: white;
  margin-bottom: 8px;

  @media (max-width: 1000px) {
    font-size: 32px;
  }
`
