import styled from 'styled-components'
import { Form as Unform } from '@unform/web'
import InputComponent from '../../components/Input'
import { primaryColor } from 'styles/colors'

export const Container = styled.div`
  display: flex;
  padding: 50px 0;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #ddd;
  padding: 20px;

  button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    border-radius: 100px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 10px;
    font-weight: 500;
    transition: border-color 0.4s;
    font-size: 12px;

    svg {
      height: 30px;

      &.google {
      }

      &.facebook {
        color: #4462a3;
      }
    }

    span {
      font-size: 12px;
    }

    & + button {
      margin-top: 10px;
    }

    :hover {
      border-color: ${primaryColor};
    }
  }
`

export const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #ddd;
  flex-direction: column;

  @media (max-width: 700px) {
    border: none;
    border-top: 2px solid #ddd;
    margin-top: 30px;
  }
`

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 90%;
  max-width: 350px;
`

export const Label = styled.span`
  font-weight: 600;
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
`

export const Input = styled(InputComponent)``

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  h2 {
    margin: 0 0 10px;
  }

  p {
    margin: 10px 0;
    text-align: center;
    color: #aaa;
  }

  div {
    display: flex;
    justify-content: space-around;

    button {
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 100px;
      flex: 1;
      margin: 10px 5px 0;
      transition: all 0.4s;

      &.white-btn {
        border: 1px solid ${primaryColor};
        color: ${primaryColor};
        background-color: #fff;

        :hover {
          border: 1px solid ${primaryColor};
          color: #fff;
          background-color: ${primaryColor};
        }
      }

      &.blue-btn {
        border: 1px solid ${primaryColor};
        color: #fff;
        background-color: ${primaryColor};

        :hover {
          border: 1px solid ${primaryColor};
          color: ${primaryColor};
          background-color: #fff;
        }
      }
    }
  }
`

export const RightContainer = styled.div`
  button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    border-radius: 100px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 10px;
    font-weight: 500;
    transition: border-color 0.4s;
    font-size: 12px;

    svg {
      height: 30px;

      &.google {
      }

      &.facebook {
        color: #4462a3;
      }
    }

    span {
      font-size: 12px;
    }

    & + button {
      margin-top: 10px;
    }

    :hover {
      border-color: ${primaryColor};
    }
  }
`
