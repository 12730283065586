import React from 'react'
// import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

// import store from 'store'
import Layout from 'components/Layout'

import ScrollToTop from 'components/ScrollToTop'

import './styles/main.css'
import './styles/modal.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import AppProvider from './hook'
import Routes from 'Routes'

ReactDOM.render(
  <Router>
    <AppProvider>
      <ScrollToTop />
      <Layout>
        <Switch>
          <Routes />
        </Switch>
      </Layout>
    </AppProvider>
  </Router>,
  document.getElementById('root'),
)
