import React from 'react'
import { Primary, Secondary, Tertiary, Orange, OrangeSecondary } from './styles'
export interface DefaultElementProps {
  className?: string
  theme?: 'primary' | 'secondary' | 'tertiary' | 'orange' | 'orange-secondary'
}

interface ButtonProps
  extends React.InputHTMLAttributes<HTMLButtonElement>,
    DefaultElementProps {
  type?: 'button' | 'reset' | 'submit'
}

const Button: React.FC<ButtonProps> = ({
  children,
  theme = 'primary',
  type = 'button',
  ...rest
}) => {
  let Button = null
  switch (theme) {
    case 'secondary':
      Button = Secondary
      break
    case 'tertiary':
      Button = Tertiary
      break
    case 'orange':
      Button = Orange
      break
    case 'orange-secondary':
      Button = OrangeSecondary
      break
    default:
      Button = Primary
  }

  return (
    <Button type={type} {...rest}>
      {children}
    </Button>
  )
}

export default Button
