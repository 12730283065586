export function formatCurrencyBRL(currency?: number | string) {
  if (!currency) {
    return '0,00 (gratuito)'
  }
  if (typeof currency === 'number') {
    return currency
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  return currency.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}
