import React from 'react'
// import ReactModal from 'react-modal'
// import { Container } from './styles';

interface ModalProps {
  onClose: () => void
  isOpen: boolean
  overlayStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
  ref?: React.MutableRefObject<null>
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  overlayStyle,
  contentStyle,
  children,
}) => {
  return null
  // return (
  //   <ReactModal
  //     isOpen={isOpen}
  //     onAfterOpen={() => {
  //       document.body.style.overflow = 'hidden'
  //     }}
  //     onAfterClose={() => {
  //       document.body.style.overflow = 'hidden'
  //     }}
  //     onRequestClose={onClose}
  //     style={{
  //       overlay: {
  //         zIndex: 10,
  //         background: 'rgba(0, 0, 0, 0.5)',
  //         ...overlayStyle,
  //       },
  //       content: {
  //         backgroundColor: '#fff',
  //         width: 'fit-content',
  //         height: 'fit-content',
  //         ...contentStyle,
  //       },
  //     }}
  //     className={
  //       'react-modal-content'
  //       /* String className to be applied to the modal content.
  //      See the `Styles` section for more details. */
  //     }
  //     ariaHideApp={
  //       false
  //       /* Boolean indicating if the appElement should be hidden */
  //     }
  //   >
  //     {children}
  //   </ReactModal>
  // )
}

export default Modal
