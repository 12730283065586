import styled from 'styled-components'

interface ContainerProps {
  readonly imageSrc: null | string
}

interface LevelIconProps {
  readonly active: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: 100%;
  min-height: 150px;
  min-width: 150px;
  width: 100%;
  ${({ imageSrc }) => `
    background: url("${imageSrc}") no-repeat center;
    background-size: cover;
  `};
  border-radius: 8px;
`

export const ContentLayer = styled.div`
  /* background: linear-gradient(
    0deg,
    rgba(28, 128, 135, 1) 0%,
    rgba(33, 164, 172, 1) 27%,
    rgba(33, 164, 172, 0.3) 100%
  );
  opacity: 0.4; */

  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`

export const PlayIcon = styled.div`
  height: 108px;
  width: 108px;
  align-self: center;
  cursor: pointer;
`
export const LevelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const LevelIcon = styled.img.attrs<LevelIconProps>(
  (props: LevelIconProps) => ({
    src: props.active
      ? '/assets/icons/favorite_full.png'
      : '/assets/icons/favorite_cinza.png',
  }),
)<LevelIconProps>`
  height: 20px;
`

export const Duration = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: white;
  font-size: 24px;
  font-weight: 500;
`
