import styled from 'styled-components'
import { primaryColor } from 'styles/colors'

export const Container = styled.div`
  background-color: #fff;
  padding-bottom: 2rem;
  max-width: 300px;
  min-width: 200px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  h3 {
    font-size: calc(100% + 1.5vw);
    font-weight: 300;
    margin: 0;
  }
  h4 {
    font-size: calc(100%);
    font-weight: 300;
    margin: 0;
  }

  .color-header {
    background: linear-gradient(200.86deg, #27d2d9 -0.06%, #1d7a7d 102.31%),
      #29a5aa;
    height: 200px;
    width: 100%;
    border-radius: 15px;
    color: white;
    padding: 1em;
  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${primaryColor};
    margin-top: 1rem;
    color: white;

    span {
      font-size: 4rem;
      font-family: Arial, Helvetica, sans-serif;

      sup {
        top: -1.4em;
        font-size: 2rem;
      }
    }

    small {
      color: white;
    }
  }

  li {
    margin-top: 10px;
    border-bottom: 2px dotted #d5d5d5;
  }

  p {
    margin: 0.5rem 0 2rem;
    text-align: start;
    line-height: 1.4;
  }

  button {
    background-color: ${primaryColor};
    color: #fff;
    padding: 8px 1.5rem;
    margin: 0 auto;
    border-radius: 50px;
    text-decoration: none;
    transition: 300ms ease-in-out;
    font-weight: 600;
    border: none;
    cursor: pointer;

    :hover {
      background-color: #ff9a31;
    }
  }
`
