/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import { useHistory, withRouter } from 'react-router'
import Register from './Register'
import { useAuth } from 'hook/Auth'
import { useToast } from 'hook/Toast'
import { SELECTED_COURSE_TO_PURCHASE_KEY } from './configs'
import { ICourseData } from '@types'
import BreadCrumbCheckout from 'components/BreadCrumbCheckout'
import Loading from 'components/Loading'
import api from 'services/api'

const Checkout = withRouter(({ location: { pathname } }: any) => {
  const history = useHistory()
  const { user } = useAuth()
  const [course, setCourse] = useState<ICourseData | undefined>()
  const { addToast } = useToast()

  useEffect(() => {
    if (!user) {
      history.replace('/app/cadastre-se')
      addToast({
        title: 'Cadastro necessário',
        description:
          'Você precisa estar logado para continuar no processo de compra',
        time: 5000,
      })
      return
    }
    async function fetchCourse(courseSlug: string) {
      const { data } = await api.get('/courses/' + courseSlug)
      localStorage.setItem(
        SELECTED_COURSE_TO_PURCHASE_KEY,
        JSON.stringify(data),
      )
      setCourse(data)
    }
    const selectedCourse = localStorage.getItem(SELECTED_COURSE_TO_PURCHASE_KEY)
    if (selectedCourse) {
      const parsed = JSON.parse(selectedCourse) as ICourseData
      setCourse(parsed)

      fetchCourse(parsed.slug)
      // const { data } = await api.get('/courses/' + parsed.id)
      if (parsed.userHasAccess) {
        history.push('/app/checkout/finalizacao')
      } else {
        history.replace('/app/checkout/cadastro-dos-dados')
      }
    }

    // if (!pricingCard && !pricingInfo) {
    //   history.push('/app/checkout/selecione-o-plano')
    //   return
    // }
    // if (pricingCard && !pricingInfo) {
    //   history.push('/app/checkout/cadastro-dos-dados')
    //   return
    // }
    // if (pricingCard && pricingInfo) {
    //   history.push('/app/checkout/finalizacao')
    //   return
    // }

    // history.push('/app/checkout/selecione-o-plano')
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <BreadCrumbCheckout />
      {!course && <Loading />}
      {course && (
        <>
          {pathname.includes('cadastro-dos-dados') && (
            <Register course={course} />
          )}
          {pathname.includes('finalizacao') && (
            <Register course={course} finished />
          )}
        </>
      )}
    </Container>
  )
})

export default Checkout
