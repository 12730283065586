import React from 'react'
import {
  Container,
  CardWrapper,
  CourseTitle,
  CourseTeacherName,
} from './styles'
// import { MdAccessTime } from 'react-icons/md'
import VideoCard from 'components/VideoCard/'
import { ICourseTypes, IHomeCourse } from '@types'

interface CourseItemProps extends React.InputHTMLAttributes<HTMLDivElement> {
  course: IHomeCourse
  type?: ICourseTypes
}

const CourseItem: React.FC<CourseItemProps> = ({ course, type, ...rest }) => {
  return (
    <Container {...rest}>
      <CardWrapper>
        <VideoCard
          style={{ height: 188, width: 310 }}
          coverImageUrl={course.image}
          publish={course.publish}
          // duration={`${course.duration} min`}
          // level={course.level}
          url={`/app/${type}/${course.slug}`}
        />
        <CourseTitle to={`/app/${type}/${course.slug}`}>
          {course.name}
        </CourseTitle>
        {course.teacher && (
          <>
            <CourseTeacherName>{course.teacher.name}</CourseTeacherName>
            {/* <CourseDetails>
              <MdAccessTime style={{ marginRight: 4 }} />
              {`${course.duration} min`}
            </CourseDetails> */}
          </>
        )}
      </CardWrapper>
    </Container>
  )
}

export default CourseItem
