import styled, { css } from 'styled-components'
import { primaryColor } from 'styles/colors'

export const Container = styled.section`
  height: 100%;
  background: #fafafa;
  border: 1px solid #ddd;

  h3 {
    margin: 0;
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  ol {
    padding: 0;
  }

  @media screen and (max-width: 1015px) {
    height: auto;
  }
`

export const VideoItem = styled.button<{ active?: boolean }>`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;

  ${({ active }) => active && css`
    background: ${primaryColor};
    color: #fff;
  `}

  .name {
    display: flex;
    align-items: left;
    text-align: left;
    margin-right: 1em;

    svg {
      margin-right: 2em;
      min-width: 16px;
      min-height: 16px;
    }
  }

  .duration {
    align-self: flex-end;
    min-width: 50px;
  }

  :hover {
    background: ${primaryColor};
    color: #fff;
  }

  :focus {
    outline: none;
  }
`

export const ProgressContainer = styled.div`
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
    border: none;
    padding: 20px 40px;
    margin: 10px 0 20px;
    background: ${primaryColor};
    color: #fff;
    border-radius: 100px;
    transition: all 0.2s;

    :hover {
      background: #fff;
      color: ${primaryColor};
      border: 2px solid ${primaryColor};
    }
  }
`

export const ProgressBarContainer = styled.div`
  padding: 0;
  height: 12px;
  width: 80%;
  margin: 0 8px;
  border-radius: 100px;
  border: 0.1px #ddd solid;
`

export const ProgressBar = styled.div<{ percent: number }>`
  padding: 0;
  margin: 0;
  border-radius: 100px;
  background-color: ${primaryColor};
  height: 10px;
  width: ${({ percent }) => percent}%;
`

export const ProgressNumber = styled.p`
  color: ${primaryColor};
  font-size: 18px;
  margin: 8px;
`
