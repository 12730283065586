/* eslint-disable multiline-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import * as yup from 'yup'
import PricingCard from 'components/PricingCard'
import { Main } from '../styles'
import getValidationErrors from 'utils/getValidationErrors'
import { useToast } from 'hook/Toast'
import { useHistory } from 'react-router'
import {
  FinishButton,
  FinishedContainer,
  Form,
  Input,
  Label,
  Left,
  Right,
} from './styles'
import Button from 'components/Button'
import WhatAreYouBuying from 'components/WhatAreYouBuying'
import api from 'services/api'
import { FaCheck } from 'react-icons/fa'
import { IPricingCard } from '../SelectPlan'
import Select from 'components/Select'
import states from './states'
import { useAuth } from 'hook/Auth'
import InputMask from 'components/InputMask'

interface IFormData {
  card_holder_name: string
  cpf: string
  cardNumber: string
  card_expiration_date: string
  card_cvv: string
  cep: string
  uf: string
  city: string
  neighborhood: string
  street: string
  number: string
  complement: string
  parcel: string
}

type IParcelArray = { label: string; value: string }[]

const Register: React.FC<{ finished?: boolean }> = ({ finished }) => {
  const [loading, setLoading] = useState(false)
  const [pricingCard, setPricingCard] = useState({} as IPricingCard)
  const [parcelArray, setParcelArray] = useState<IParcelArray>([])

  const formRef = useRef<FormHandles>(null)

  const { addToast } = useToast()
  const { token } = useAuth()
  const history = useHistory()

  useEffect(() => {
    async function loadData() {
      try {
        const card = localStorage.getItem('@HealthPlay:PricingCard')
        // const info = localStorage.getItem('@HealthPlay:PricingInfo')

        const { data } = await api.get('/user/my-profile', {
          headers: { authorization: token },
        })

        if (card) {
          const cardValues = JSON.parse(card)
          setPricingCard(cardValues)

          const array = []
          const totalParcels = cardValues.installments
          for (let i = 1; i <= totalParcels; i++) {
            array.push({ label: `${i}x`, value: `${i}` })
          }

          setParcelArray(array)
        }
        // if (info) setPricingInfo({ ...JSON.parse(info), ...data.user })
        // else setPricingInfo({ ...data.user })

        formRef.current?.setData({
          ...data.user,
          cpf: data.user?.cpf || '',
          phone: data.user?.phone || '',
          ...data.user?.address,
          cep: data.user?.address?.cep || '',
          uf: data.user?.address?.uf
            ? { label: data.user?.address?.uf, value: data.user?.address?.uf }
            : undefined,
          card_holder_name: data.user.name || '',
          cardNumber: '',
          card_expiration_date: '',
          card_cvv: '',
        })
      } catch (err) {
        history.replace('/')
        addToast({
          title: 'Ops...',
          description: 'Ocorreu algum erro, tente novamente mais tarde.',
          time: 5000,
          type: 'error',
        })
      }
    }

    loadData()
  }, [addToast, history, token])

  const handleSubmit = useCallback(
    async (values: IFormData): Promise<void> => {
      setLoading(() => true)
      formRef.current?.setErrors({})
      try {
        await yup
          .object()
          .shape({
            card_holder_name: yup.string().required('O nome é obrigatório'),
            cpf: yup.string().required('O CPF é obrigatório'),
            cardNumber: yup
              .string()
              .required('O número do cartão é obrigatório'),
            card_expiration_date: yup
              .string()
              .required('A data de validade é obrigatória'),
            card_cvv: yup
              .number()
              .typeError('O campo CVV tem que ser um número')
              .required('O código de segurança é obrigatório'),
            parcel: yup.string().required('A parcela é obrigatória'),
            cep: yup.string().required('O CEP é obrigatório'),
            phone: yup
              .string()
              .matches(/\(\d{2}\)\s{1}\d{5}-\d{4}/, 'Digite um número válido')
              .required('O celular é obrigatório'),
            uf: yup.string().required('O estado é obrigatório'),
            city: yup.string().required('A cidade é obrigatória'),
            neighborhood: yup.string().required('O bairro é obrigatório'),
            street: yup.string().required('A rua é obrigatória'),
            number: yup
              .number()
              .typeError('O campo número tem que ser um número')
              .required('O número é obrigatório'),
            complement: yup.string().notRequired(),
          })
          .validate(
            {
              ...values,
              card_cvv: values.card_cvv
                ? values.card_cvv.replace(/_/g, '')
                : undefined,
            },
            {
              abortEarly: false,
            },
          )

        await api.post(
          '/payments/update-address-plan-creditCard',
          {
            planId: pricingCard.id,
            ...values,
            cardNumber: values.cardNumber.replace(/\s/g, ''),
            card_expiration_date: values.card_expiration_date.replace('/', ''),
          },
          { headers: { authorization: token }, withCredentials: true },
        )

        addToast({
          type: 'success',
          title: 'Registrado com sucesso',
        })
        localStorage.setItem('@HealthPlay:PricingInfo', JSON.stringify(values))
        history.push('/app/planos/finalizacao')
      } catch (error) {
        const err = error as any
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        } else if (err.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para isto',
          })
        } else {
          addToast({
            type: 'error',
            title: 'OPS...',
            description:
              err.response?.data.message ||
              'Ocorreu algum erro, tente novamente',
          })
        }
      } finally {
        setLoading(() => false)
      }
    },
    [addToast, history, pricingCard.id, token],
  )

  const handleCEP = useCallback(
    async (value: string) => {
      try {
        if (value) {
          const { data } = await api.get(
            `https://viacep.com.br/ws/${value}/json/`,
          )

          formRef.current?.setData({
            ...formRef.current?.getData(),
            city: data.localidade,
            neighborhood: data.bairro,
            street: data.logradouro,
            uf: {
              value: data.uf,
              label: data.uf,
            },
          })
          // const a = formRef.current?.setFieldValue('uf', )
          // console.log(a)
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu algum erro',
          description: 'Tente novamente',
        })
      }
    },
    [addToast],
  )

  return (
    <Main style={{ margin: '40px 0' }}>
      <Left>
        {pricingCard && <PricingCard value={pricingCard} hideClick />}
        <WhatAreYouBuying />
      </Left>
      <Right>
        {finished ? (
          <FinishedContainer>
            <div className="icon-container">
              <FaCheck size={35} />
            </div>
            <strong>
              COMPRA REALIZADA
              <br />
              COM SUCESSO
            </strong>
            <p>Parabéns, agora você pode ter acesso as nossas video aulas</p>
            <p>
              Conteúdo de qualidade para potencializar o seu conhecimento, a
              qualquer hora do dia, por computador, tablet ou smatphone
            </p>
            <FinishButton
              onClick={() => {
                history.push('/app')
                localStorage.removeItem('@HealthPlay:PricingCard')
              }}
            >
              ACESSAR VÍDEO AULAS
            </FinishButton>
          </FinishedContainer>
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h3>1- Dados do Cartão de Crédito</h3>
            <Label>Nome do titular do cartão</Label>
            <Input
              name="card_holder_name"
              placeholder="Digite o nome do titular do cartão"
            />
            <Label>CPF</Label>
            <InputMask
              name="cpf"
              placeholder="Digite seu CPF"
              mask="999.999.999-99"
            />
            <Label>Celular</Label>
            <InputMask
              name="phone"
              placeholder="Digite seu celular"
              mask="(99) 99999-9999"
            />
            <Label>Número do cartão</Label>
            <InputMask
              name="cardNumber"
              placeholder="Digite o número do cartão"
              mask="9999 9999 9999 9999"
            />
            <div className="flex">
              <div className="item">
                <Label>Validade</Label>
                <InputMask
                  name="card_expiration_date"
                  placeholder="Data de validade"
                  mask="99/99"
                />
              </div>
              <div className="item" style={{ marginLeft: 10 }}>
                <Label>CVV</Label>
                <InputMask name="card_cvv" placeholder="CVV" mask="9999" />
              </div>
            </div>
            <Label>Parcelas</Label>
            <Select
              name="parcel"
              values={parcelArray}
              placeholder="Selecione a parcela"
            />
            <h3>2- Dados de Endereço</h3>
            <Label>CEP</Label>
            <InputMask
              name="cep"
              placeholder="Digite o CEP"
              onBlur={(e) => handleCEP(e.target.value)}
              mask="99999-999"
            />
            <div className="flex">
              <div className="item" style={{ flex: 1 }}>
                <Label>Estado</Label>
                <Select
                  name="uf"
                  values={states}
                  placeholder="Selecione o estado"
                />
                {/* <Input name="uf" placeholder="Digite o estado" defaultValue={pricingInfo?.address?.uf} /> */}
              </div>
              <div className="item" style={{ marginLeft: 10 }}>
                <Label>Cidade</Label>
                <Input name="city" placeholder="Digite a cidade" />
              </div>
            </div>
            <Label>Endereço</Label>
            <Input name="street" placeholder="Digite a rua" />
            <div className="flex">
              <div className="item">
                <Label>Número</Label>
                <Input
                  name="number"
                  placeholder="Digite o número"
                  type="number"
                />
              </div>
              <div className="item" style={{ marginLeft: 10 }}>
                <Label>Bairro</Label>
                <Input name="neighborhood" placeholder="Digite o bairro" />
              </div>
            </div>
            <Label>Complemento</Label>
            <Input name="complement" placeholder="Digite o complemento" />
            <Button type="submit" style={{ marginTop: 10 }} disabled={loading}>
              REALIZAR COMPRA
            </Button>
          </Form>
        )}
      </Right>
    </Main>
  )
}

export default Register
