import styled from 'styled-components'

import { primaryColor } from 'styles/colors'
import { CleanLink as CleanLinkBase } from 'styles/'

export const Container = styled.div``

export const CleanLink = styled(CleanLinkBase)`
  margin-bottom: 12px;
`
export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 5%;
  background-color: ${primaryColor};
`
export const FooterItem = styled.div`
  display: flex;
  margin: 0 16px;
`

export const SocialMediaIcon = styled.img`
  height: 32px;
  object-fit: contain;
  margin: 0 16px;
  cursor: pointer;
`
