import React, { useEffect } from 'react'
import { useAuth } from 'hook/Auth'
import { useToast } from 'hook/Toast'
import { useQuery } from 'hook/useQuery'
import { useHistory } from 'react-router'
import Loading from '../../components/Loading'
import api from 'services/api'

// const affiliatedCodeCookieName = '@HealthPlay:affiliatedCode'
// const affiliatedExpiresCookieName = '@HealthPlayaffiliatedExpires'

export const AffiliatedLink = () => {
  const query = useQuery()
  const affiliatedCode = query.get('codigo')
  console.log({ affiliatedCode })
  const { addToast } = useToast()
  const { token, user } = useAuth()
  const history = useHistory()
  const isLoggedIn = token && user

  function redirectUser() {
    if (isLoggedIn) {
      history.replace('/app/planos/selecione-o-plano')
    } else {
      history.replace('/app/cadastre-se')
    }
  }

  useEffect(() => {
    async function setupAffiliatedCookie() {
      try {
        await api.post(
          `/affiliated_view/setup_cookie/${affiliatedCode}`,
          null,
          { withCredentials: true },
        )
        redirectUser()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Houve um erro ao configurar o cadastro',
        })
      } finally {
        // redirectUser()
      }
    }
    setupAffiliatedCookie()
  }, [])
  return <Loading show={true} />
}
