import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
// import AsyncSelect, { Props as SelectProps } from 'react-select/async'

import { Container } from './styles'

interface ISelectProps extends SelectProps<OptionTypeBase> {
  name: string
  label?: string
  values: { value: string; label: string }[]
  containerStyle?: React.CSSProperties
}

const Select: React.FC<ISelectProps> = ({
  name,
  label,
  values,
  containerStyle,
  ...rest
}) => {
  const selectRef = useRef(null)

  const { fieldName, registerField, defaultValue, error } = useField(name)
  // const [defaul] = useState(defaultValue)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.value
      },
      setValue: (ref: any, value: any) => {
        ref.select.setValue(value)
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <>
      <Container style={containerStyle}>
        {label && (
          <div className="label">
            <strong>{label}</strong>
          </div>
        )}
        <div className="input">
          <ReactSelect
            ref={selectRef}
            options={values}
            defaultValue={defaultValue}
            className="react-select"
            styles={{ control: (styles) => ({ ...styles, padding: 5 }) }}
            {...rest}
          />
        </div>
      </Container>
      {error && <small style={{ color: '#fa4545' }}>{error}</small>}
    </>
  )
}

export default Select
