import React from 'react'

// import { Container } from './styles';

const Logo: React.FC<{ color?: 'green' | 'white' }> = ({
  color = 'green',
  ...rest
}) => (
  <a href='/app'>
    <img
      style={{ height: 60, minWidth: 168, objectFit: 'contain' }}
      src={
        color === 'green'
          ? '/assets/icons/icon_logo.svg'
          : '/assets/icons/icon_logo_white.png'
      }
      {...rest}
      alt="HealthPlay"
    />
  </a>
)
export default Logo
