interface IGatewayCard {
  card_number: string // '406181981981981'
  card_holder_name: string // 'abc def'
  card_expiration_date: string // '1225'
  card_cvv: string // '123'
}
export async function generateCardHash(card: IGatewayCard): Promise<string> {
  const encryptionKey = process.env.REACT_APP_PAGARME_ENCRYPT_KEY
  if (!encryptionKey) {
    throw new Error('REACT_APP_PAGARME_ENCRYPT_KEY  env var not found')
  }

  const pagarme = await import('pagarme')
  const client = await pagarme.client.connect({
    encryption_key: encryptionKey,
  })
  const hash = await client.security.encrypt(card)
  return hash
}

export function testCardHashGeneration() {
  console.log('Generating Hash')
  generateCardHash({
    card_number: '4024007118841559',
    card_holder_name: 'Geraldo Silvano',
    card_expiration_date: '0826',
    card_cvv: '222',
  }).then((hash) => {
    console.log({ hash })
  })
}
