import React from 'react'
import { CarouselProvider, Slide } from 'pure-react-carousel'
import {
  Container,
  SeeAll,
  SingleCourse,
  Slider,
  ButtonBack,
  ButtonNext,
} from './styles'
import { CourseAreaTitle } from 'styles/'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { ICourseTypes, IListAula } from '@types'
interface CourseAreaScrollProps
  extends React.InputHTMLAttributes<HTMLDivElement> {
  courses: IListAula[]
  name?: string
  showSeeAll?: boolean
  seeAllRoute?: string
  type?: ICourseTypes
}

const CourseAreaScroll: React.FC<CourseAreaScrollProps> = ({
  name,
  courses,
  showSeeAll,
  seeAllRoute,
  type,
}) => {
  const [visibleSlides, setVisibleSlides] = React.useState(0)

  const updateScreenSize = () => {
    let visible

    if (window.innerWidth > 1000) {
      visible = 3
    } else if (window.innerWidth > 600) {
      visible = 2
    } else {
      visible = 1
    }
    setVisibleSlides(courses.length < visible ? courses.length : visible)
  }

  React.useEffect(() => {
    window.addEventListener('resize', updateScreenSize)
    return () => window.removeEventListener('resize', updateScreenSize)
  })

  React.useEffect(updateScreenSize, [])

  return (
    <Container>
      {name && <CourseAreaTitle>{name}</CourseAreaTitle>}

      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={courses.length}
        visibleSlides={visibleSlides}
        isIntrinsicHeight
      >
          {/* REVERSE - adicionado para mostrar os últimos adicionados primeiro
                TODO: Ajustar no Backend  */}
        <Slider>
          {courses.reverse().map((course: any, idx: number) => (
            <Slide index={idx} key={idx}>
              <SingleCourse
                key={idx}
                course={course}
                type={type}
                totalSlides={courses.length}
              />
            </Slide>
          ))}
        </Slider>
        <ButtonBack>
          <MdChevronLeft size={45} />
        </ButtonBack>
        <ButtonNext>
          <MdChevronRight size={45} />
        </ButtonNext>
      </CarouselProvider>
      {showSeeAll && <SeeAll to={seeAllRoute || '/app'}>Ver todos</SeeAll>}
    </Container>
  )
}
export default CourseAreaScroll
