import styled from 'styled-components'
import { primaryColor } from 'styles/colors'
// import landingBanner from 'assets/LP/'
import missionHappy from 'assets/LP/fundo-niveis.jpg'

export const LandingBannerWrapper = styled.div`
  background-image: url('https://i.vimeocdn.com/video/1721448104-090c8de0a4431068c4e6e8ec92e2474a709084ee9cc07d2ed246d4baf47c2be3-d?mw=3400&mh=1913&q=70');

   background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 5%;
  background: linear-gradient(
    180deg,
    rgba(0, 23, 29, 1) 0%,
    rgba(0, 23, 29, 0) 100%
  );
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 999;

  img {
    width: 220px;
  }

  nav.landing-nav {
    display: flex;
    align-items: center;

    button {
      &.nav-link {
        border: none;
        background: #21a4ac;
        color: #fff;
        margin: 0 0.5rem;
        padding-bottom: 5px;
        font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        transition: color 300ms ease-in-out;

        :hover {
          border-bottom: 3px solid #fff;
          color: #fff;
        }
      }
    }

    a {
      background-color: #21a4ac;
      color: #fff;
      padding: 5px 1.5rem;
      border-radius: 50px;
      text-decoration: none;
      transition: 300ms ease-in-out;
      font-weight: 600;
      height: 42px;
      align-self: center;
      display: flex;
      align-items: center;

      :hover {
        color: #fff;
        background-color: #2bb47e;
      }
    }

    .flags {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      img {
        width: 20px;
        height: 15px;
        object-fit: cover;
        object-position: top;
        margin-bottom: 5px;
      }
    }
  }

  button.close {
    position: absolute;
    right: 1.6rem;
    border: none;
    border-bottom: none;
    background: none;
    width: fit-content;
    height: 30px;
    padding: 0;
    cursor: pointer;
    display: none;
  }

  @media (max-width: 1215px) {
    nav.landing-nav {
      button.nav-link {
        margin: 0 0.3rem;
      }
    }
  }

  @media (max-width: 1168px) {
    padding: 2rem 4%;
  }

  @media (max-width: 1026px) {
    justify-content: center;

    nav.landing-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      width: 240px;
      height: 100vh;
      padding: 100px 1rem 0;
      background-color: ${primaryColor};
      box-shadow: 0 0 20px #00000044;
      transition: transform 400ms;
      transform: translateX(270px);

      &.show-nav {
        transform: translateX(0);
      }

      button {
        &.nav-link {
          margin: 0.3rem 0.3rem;
          text-align: end;
        }
      }

      a {
        text-align: center;
      }

      .flags {
        flex-direction: row;
        margin-left: 0;
        margin-top: 1rem;

        img {
          margin-bottom: 0;
          margin-left: 5px;
        }
      }
    }

    button.close {
      display: block;
    }
  }
`

export const Banner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 5%;
 background-color: blue; 
  /* opacity: 0.7; */
  // background-color: rgba(33, 164, 172, 0.89);
  background: linear-gradient(180deg, #00171D 0%, rgba(0, 23, 29, 0.6) 16.67%, rgba(0, 23, 29, 0) 38.54%, rgba(0, 23, 29, 0) 63.54%, rgba(0, 23, 29, 0.6) 85.42%, #00171D 100%);
  height: 840px;
  h2 {
    text-align: left;
    font-size: calc(100% + 1.8vw);
    font-weight: 800;
    color: #fff;
    margin-bottom: 2%;
    width: 30%;
    font-size:34px;
    line-height: 1.2;
  }

  h3 {
    font-size: calc(100% + 0.5vw);
    width: 40%;
    text-align: left;
    color: #fff;
    font-weight: 400;
    margin-top: 0;
    line-height: 1.4;
    font-size:22px;
    margin-bottom: 60px;
  }

  button {
    border: 0;
    background: 0;
    cursor: pointer;
    margin-top: 5%;

    &.btn-banner {
      background-color: #fff;
      color: ${primaryColor};
      padding: 1.2rem 3rem;
      border-radius: 10rem;
      font-weight: bold;
      transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

      &:hover {
        color: #fff;
        background-color: #000;
      }
    }
  }

  @media (max-width: 1026px) {

    h2,h3 {
      width: 100%;
      text-align:center;
  }


`

export const BannerWrapperColor = styled.div`
@media (max-width: 1026px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,90%);
  width:100%;
  border-radius: 10px;
  margin-bottom: 20px;
  padding:4%;
  img {
    display: flex !important;
    width: 50% ;
    justify-content: center;
    align-itens: center;
    
  }
  }
`

export const BannerWrapper = styled.div`
  margin-top: 10%;
  width: 100%;
  align-selft: flex-start;

  @media (max-width: 1026px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    margin-top: 90%;
    }
 
`

export const Beneficios = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 5rem 5%;
  /* background-color: #f5f5f5; */

  background: linear-gradient(
      180deg,
      #00171d 0%,
      rgba(0, 23, 29, 0.6) 22.7%,
      rgba(0, 23, 29, 0) 49.8%,
      rgba(0, 23, 29, 0.6) 76.4%,
      #00171d 100%
    ),
    url(${missionHappy});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .titleMission {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    h2 {
      font-weight: 300;
      font-size: 18px;
      line-height: 1.6;
      text-align: left;
      text-rendering: optimizeLegibility;
      color: #f2f3f4;
      letter-spacing: 0.36px;
      margin-bottom: 0;
    }
    h1 {
      margin-top: 0;
      font-weight: 800;
      font-size: 45px;
      line-height: 1.6;
      margin-bottom: 12px;
      text-align: left;
      text-rendering: optimizeLegibility;
      color: #f2f3f4;
      letter-spacing: 0.36px;
    }
  }

  .card {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;

    width: 470px;

    span {
      background-color: #21a4ac;
      display: inline-block;
      margin: 30px auto 15px auto;
      padding: 6px 15px;
      color: #f2f3f4;
      font-size: 12px;
      font-weight: 700;
      border-radius: 11px;
      text-transform: uppercase;
    }
    img {
      width: 50px;
      height: 50px;
    }

    h3 {
      text-rendering: optimizeLegibility;
      font-weight: 700;
      margin: 0 0 16px 0;
      line-height: 1.4;
      color: #f2f3f4;
      letter-spacing: 0.66px;
      font-size: 28px;
      margin-bottom: 0;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 12px;
      text-align: center;
      text-rendering: optimizeLegibility;
      color: #f2f3f4;
      letter-spacing: 0.36px;
    }
  }

  @media (max-width: 1026px) {
    padding: 5rem 5%;
    .card {
      padding: 0 2rem;
    }
  }
`

export const ComoFunciona = styled.section`
  background-color: #f5f5f5;
  border-top: 0.5px solid #eee;
  text-align: center;
  padding: 7rem 5%;

  h2 {
    font-size: calc(100% + 1.6vw);
    font-weight: 800;
    margin-bottom: 0px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #292929;
    line-height: 2;
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
  }

  iframe {
    margin-top: 3rem;
    width: 100%;
    max-width: 650px;
    height: 366px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .btn {
    color: #fff;
    background-color: ${primaryColor};
    padding: 1rem 2rem;
    border-radius: 10rem;
    font-weight: bold;
    cursor: pointer;
    border: 0;
    margin-top: 1rem;
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

    &:hover {
      color: #fff;
      background-color: #292929;
    }
  }
`

export const OQueContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 28%;
  background: #00171d !important;
  color: #f2f3f4;
  line-height: 1.6;

  h2 {
    text-align: center;
    font-size: calc(100% + 0.5vw);
    font-weight: 400;

    span {
      color: #21a4ac;
      font-weight: 700;
    }
  }
  p {
    display: flex;
    justify-content: center;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin-bottom: 12px;
    text-align: center;
    letter-spacing: 0.36px;
    margin-top: 0;
  }

  @media (max-width: 1026px) {
    padding: 2rem 8%;
  }
`

export const EBook = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  justify-items: center;
  border-top: 0.5px solid #eee;
  padding: 5rem 5%;

  form {
    max-width: 600px;
  }

  h2 {
    text-align: left;
    font-size: calc(100% + 2.6vw);
    font-weight: 800;
    margin-bottom: 20px;
  }

  p {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    color: #292929;
    line-height: 2;
    margin-bottom: 2rem;
  }

  input::placeholder {
    color: #555;
  }

  button[type='submit'] {
    text-transform: capitalize;
    padding: 1.2rem 2.8rem;
    border-radius: 50px;
    background-color: ${primaryColor};
    border: 0;
    margin-top: 1rem;
    color: #fff;
    cursor: pointer;
    float: right;
    transition: 300ms ease-in-out;

    &:hover {
      background-color: #292929;
    }

    &:disabled {
      background-color: #ddd;
      color: #444;
    }
  }

  img {
    width: 100%;
    max-width: 350px;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;

    button[type='submit'] {
      float: none;
      width: 100%;
    }
  }
`

export const Planos = styled.section`
  background-color: #00171d;
  text-align: center;
  padding: 7rem 5%;
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: calc(100% + 1.6vw);
    font-weight: 800;
    margin-bottom: 10px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 2;
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .card {
    display: block;
    padding: 25px 25px 100px 25px;
    border: 2px solid #f2f3f4;
    border-radius: 12px;
    text-decoration: none;
    background: linear-gradient(
      180deg,
      #71C5A5 0%,
      #13595e 34.38%,
      #0b3f46 67.71%,
      #01171d 100%
    );
    height: 100%;
    margin: 20px;
    width: 35%;

    h3 {
      font-size: calc(100% + 1.5vw);
      font-weight: 300;
      margin: 0;
    }

    .color-header {
      height: 150px;
      width: 100%;
      border-radius: 10px 10px 0 0;
      color: white;
      padding: 1em;

      h5 {
        margin: 0;
        padding-top: 10px;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: left;
      }
      h3 {
        margin: 0;
        font-weight: 800;
        font-size: 1.4rem;
      }
    }
    .paywrapper {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
    }
    .price {
      text-align: left;
      p {
        font-size: 36px;
        font-weight: 700;
        margin: 0 0 15px 0;
        color: #f2f3f4;
        display: inline-block;

        span {
          font-size: 2.8rem;
          font-weight: 300;
        }
        sup {
          top: -0.6em;
          font-size: 1.2rem;
          opacity: 1;
        }
      }
      small {
        color: white;
        font-size: 0.8rem;
        opacity: 0.8;
      }
    }

    li {
      margin-top: 12px;
      padding-bottom: 12px;
      border-bottom: 2px dotted #d5d5d5;
      list-style: none;
    }

    p {
      margin: 0.5rem 0 2rem;
      text-align: start;
      line-height: 1.4;
    }

    a {
      background-color: ${primaryColor};
      color: #fff;
      padding: 18px 2.2rem;
      border-radius: 50px;
      text-decoration: none;
      transition: 300ms ease-in-out;
      font-weight: 600;
      margin-bottom: 40px;

      :hover {
        background-color: #292929;
      }
    }
    @media (max-width: 768px) {
      margin: 20px 0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 2rem;
    align-items: start;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`

export const FAQ = styled.section`
  background-color: #00171d;
  text-align: start;
  padding: 7rem 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  h2 {
    margin-bottom: 30px;
    font-size: 20px;
    text-rendering: optimizeLegibility;
    font-weight: 700;
    margin: 0 0 16px 0;
    line-height: 1.4;
    color: #f2f3f4;
    letter-spacing: 0.66px;
  }

  .accordion__button {
    font-weight: 300;
    font-size: 1rem;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    padding: 28px 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    /* border: solid 1px #21a4ac; */
  }

  .accordion__item {
    margin-bottom: 1rem;
    background-color: #000;
    color: #fff;
  }

  .accordion__panel {
    font-weight: 400;
    font-size: 16px;
    color: #292929;
    line-height: 2;
    max-width: 750px;
    margin: 0 auto;
    animation: fadein 0.35s ease-in;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    opacity: 0.8;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
export const RiscoZero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
    text-rendering: optimizeLegibility;
    color: #f2f3f4;
    letter-spacing: 0.36px;
    margin-bottom: 0;
  }
  h2 {
    text-align: center;
    color: #f2f3f4;
    font-size: 36px;
    margin-bottom: 30px;
    margin-top: 0;
  }
`
export const Novidades = styled.section`
  padding: 5rem 5%;
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: none;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* filter: grayscale(100%) brightness(15%) opacity(80%); */
    filter: sepia(100%) brightness(35%) opacity(80%) saturate(400%);
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      text-align: center;
      font-size: calc(100% + 1.6vw);
      font-weight: 800;
      margin-bottom: 20px;
      color: #fff;
    }

    p {
      text-align: center;
      font-weight: 400;
      font-size: 22px;
      color: #fff;
      line-height: 2;
      margin-bottom: 2rem;
    }

    .input-container {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 50px;
      overflow: hidden;
      max-width: 500px;
      width: 100%;
      max-height: 50px;

      input {
        height: 100%;
        width: 100%;
        padding: 0.7rem 1rem;
        border: 0;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        border: 0;
        padding: 0.7rem 2rem;
        color: #fff;
        background-color: ${primaryColor};
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 400ms;

        :hover {
          background-color: #292929;
        }

        &:disabled {
          background-color: #ddd;
          color: #444;
        }
      }
    }
  }
`

export const CardWrapper = styled.div`
  display: flex;
  padding: 25px 25px 100px 25px;
  justify-content: center;

  @media (max-width: 1026px) {
    flex-direction: column;

    .card {
      width: 100%;
    }
  }
`

export const ScrollToTop = styled.button.attrs({ type: 'button' })`
  position: fixed;
  bottom: 110px;
  right: 55px;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  border: 0;
  background-color: #21a4ac;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  overflow: hidden;

  :hover {
    svg {
      animation: scrollUp 700ms ease-in-out infinite;
    }
  }

  @keyframes scrollUp {
    from {
      transform: translateY(40px);
    }

    to {
      transform: translateY(-40px);
    }
  }
`
export const SectionInicio = styled.div`
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;

  align-items p {
    opacity: 0.6;
    width: 50%;
  }
`

export const VideoBackground = styled.video`
  width: 100%;
  height: 140vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
`

export const SectionInicioWrapper = styled.div`
  /* width: 1024px;
  width: 100%;
  max-width: 1024px;

  p {
    color: rgba(255, 255, 255, 0.8);
  }

  h3 {
    color: #fff;
    margin-top: 10px;
    font-family: "Montserrat", sans-serif;

    /* text-shadow: 0 0 1px #fff, 0 0 4px #fff; */
    span {
      /* text-shadow: 0 0 5px #fff, 0 0 10px #3f7fc1, 0 0 15px #3f7fc1,
        0 0 22px #3f7fc1, 0 0 12px #3f7fc1, 0 0 20px #3f7fc1,
        2px 2px 2px #3f7fc1; */

      color: #3f7fc1;
      text-shadow: 0 0 10px #3f7fc1;
      font-family: "Montserrat", sans-serif;
    }
  }
`
export const Servicos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #00171d !important;
  color: #f2f3f4;
  line-height: 1.6;
  align-items: center;

  h2 {
    padding: 5rem 28%;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.66px;
    line-height: 1.4;
    text-rendering: optimizeLegibility;

    span {
      color: #21a4ac;
      font-weight: 700;
    }
  }
  p {
    display: flex;
    justify-content: center;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin-bottom: 12px;
    text-align: center;
    letter-spacing: 0.36px;
    margin-top: 0;
  }

  @media (max-width: 1026px) {
    h2 {
      padding: 2rem 5%;
    }
  }
`

export const CardServico = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 2%;
`

export const CardServicoContent = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 20px !important;
  margin-bottom: 12px;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.36px;
  padding: 0 10px;
  width: 100%;
`
export const CardImage = styled.div`
  position: relative;
  display: inline-block;
  justify-content: flex-start;
  background-color: #00171d;

  img {
    display: block;
    width: 100%;
    height: 380px;
    // min-width: 300px;
    background-color: #00171d;
  }
  h3 {
    position: absolute;
    top: 75%;
    left: 10%;
    width: 80%;
    text-align: center;
    font-size: 20px;
  }
`
export const ServicosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  padding: 0 0.6rem;

  @media (max-width: 1026px) {
    display: flex;
    flex-direction: column;
  }
`

export const LinkCTA = styled.a`
  background-color: #21a4ac;
  color: #fff;
  padding: 5px 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  transition: 300ms ease-in-out;
  font-weight: 600;
  height: 42px;
  align-self: center;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;

  :hover {
    color: #fff;
    background-color: #2bb47e;
  }
`
export const ButtonNav = styled.div`
  background-color: #21a4ac;
  color: #fff;
  padding: 5px 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  transition: 300ms ease-in-out;
  font-weight: 600;
  height: 42px;
  align-self: center;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: #fff;
    background-color: #2bb47e;
  }
`
export const Investimento = styled.div`
  display: flex;
  flex-direction: row;
  list-style: none;

  .textInvestment {
    width: 70%;
  }
  b {
    color: #21a4ac;
  }

  li {
    display: flex;
    justify-content: space-between;
    line-height: 3;
  }

  img {
    width: 500px;
    margin-right: 20%;

  }

  h2 {
    font-weight: 700;
    font-size: 24px;

    span {
      color: #21a4ac;
    }
  }

  @media (max-width: 1026px) {
    flex-direction: column;
    .textInvestment {
      width: 100%;
    }

    img {
      margin-right: 0;
    }
  }
`
