import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 10px;

  .input {
    flex: 1;
    display: flex;
    align-items: center;
    background: #fff;
    width: 100%;
    font-size: 16px;

    h1 {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
    }

    .react-select {
      flex: 1;
      background: transparent;
      border: 0;
      color: #404040;

      &::placeholder {
        color: #404040;
      }
    }

    svg {
      margin-right: 16px;
    }
  }

  & + div {
    margin-top: 24px;
  }
`
