import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background: #292929;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`
export const Icon = styled.img`
  width: 80px;
  @media (max-width: 600px) {
    display: none;
  }
`
export const TextContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 8px;
`
export const Description = styled.div`
  font-size: 14px;
  color: #fffc;
  line-height: 1.4;
  letter-spacing: 1.2;
`
