import React, {
  createRef,
  // useCallback,
  useEffect,
  // useRef,
  // useRef,
  useState,
} from 'react'
import { IoClose } from 'react-icons/io5'
import { FaArrowUp, FaBars, FaWhatsapp } from 'react-icons/fa'
// import * as yup from 'yup'
import { Link } from 'react-router-dom'
// import { FormHandles } from '@unform/core'
import CookieConsent from 'react-cookie-consent'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
// import 'react-accessible-accordion/dist/fancy-example.css'

import logoWhite from 'assets/LP/healthplay-branco.svg'
// import iconPlayerWhite from 'assets/LP/icon_player.svg'
// import iconPlayerBlue from 'assets/LP/play-azul.svg'
// import eBookImage from 'assets/LP/capa-ebook.jpg'
// import iconEn from 'assets/LP/icon_en.png'
// import iconEs from 'assets/LP/icon_es.png'
import iconStar from 'assets/LP/icon_star.svg'
import riscoZero from 'assets/LP/riscozero.webp'

import servico1 from 'assets/LP/image1.webp'
import servico2 from 'assets/LP/image2.webp'
import servico3 from 'assets/LP/image3.webp'
import servico4 from 'assets/LP/image4.jpg'
import ineexDesconto from 'assets/LP/ineexDesconto.png'

// import investimento from 'assets/LP/imageInvest.png'

import {
  Banner,
  BannerWrapper,
  BannerWrapperColor,
  Beneficios,
  ButtonNav,
  // ComoFunciona,
  CardWrapper,
  CardServico,
  CardServicoContent,
  CardImage,
  FAQ,
  Header,
  // Investimento,
  LandingBannerWrapper,
  LinkCTA,
  // OQueContainer,
  Planos,
  RiscoZero,
  Servicos,
  ServicosWrapper,
  ScrollToTop,
  SectionInicio,
} from './styles'
import Footer from '../../components/Layout/Footer'
import { primaryColor } from 'styles/colors'
// import { Form } from '@unform/web'
// import Input from 'components/Input'
// import InputMask from 'components/InputMask'
import Modal from 'components/Modal'
// import { useToast } from 'hook/Toast'
// import getValidationErrors from 'utils/getValidationErrors'
// import api from 'services/api'

// interface IEbookFormData {
//   name: string
//   email: string
//   whatsapp?: string
// }
const LandingPage: React.FC = () => {
  // const [homeRef, beneficiosRef, bannerRef, oQueRef, eBookRef, novidadesRef] = [
  const [homeRef, beneficiosRef, planosRef] = [
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
  ]
  const [showMenu, setShowMenu] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const [resize, setResize] = useState(window.innerWidth)

  // const { addToast } = useToast()

  // const ebookFormRef = useRef<FormHandles>(null)
  // const newsletterInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // window.addEventListener('resize', () => setResize(window.innerWidth))
    document.body.classList.add('landing-page')
    return () => {
      // window.removeEventListener('resize', () => setResize(window.innerWidth))
      document.body.classList.remove('landing-page')
    }
  }, [])

  const openMenu = () => {
    document.body.classList.toggle('menu-opened')
    setShowMenu((old) => !old)
  }

  function closeMenu() {
    document.body.classList.remove('menu-opened')
    setShowMenu(false)
  }

  return (
    <>
      <LandingBannerWrapper>
        <a
          href="https://api.whatsapp.com/send?phone=5551997982266"
          className="float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp color="white" />
        </a>
        <ScrollToTop
          title="Ir ao topo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          <FaArrowUp />
        </ScrollToTop>
        <Header ref={homeRef}>
          <Link to="/" className="header-link">
            <img src={logoWhite} alt="Logo branco da HealthPlay" />
          </Link>

          <nav className={`landing-nav ${showMenu ? 'show-nav' : ''}`}>
            {/* <button
              className="nav-link"
              onClick={() => {
                if (resize <= 1026) openMenu()
                bannerRef.current?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              Início
            </button> */}
            {/* <button
              className="nav-link"
              onClick={() => {
                if (resize <= 1026) openMenu()
                oQueRef.current?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              Serviços
            </button> */}
            {/* <button
              className="nav-link"
              onClick={() => {
                if (resize <= 1026) openMenu()
                novidadesRef.current?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              Planos
            </button> */}
            {/* <button
              className="nav-link"
              onClick={() => {
                if (resize <= 1026) openMenu()
                eBookRef.current?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              Ebook
            </button> */}

            {/* <button
              className="nav-link"
              onClick={() => {
                if (resize <= 1026) openMenu()
                beneficiosRef.current?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              Missão
            </button> */}
            {/* <button
              className="nav-link"
              onClick={() => {
                if (resize <= 1026) openMenu()
                funcionaRef.current?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              Como funciona
            </button> */}

             <ButtonNav
              className="nav-link"
              style={{ fontSize: 14, fontWeight: 800, marginRight: '15px' }}
              onClick={() => {
                planosRef.current?.scrollIntoView({ behavior: 'smooth' })
              }}
            >ASSINE AGORA</ButtonNav>
            {/* <LinkCTA
              href="https://healthplaypro.themembers.com.br/"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: 14, fontWeight: 800 }}
              onClick={closeMenu}
            >
               SEJA UM HEALTHPLAYER
            </LinkCTA> */}
            {/* <div className="flags">
              <img src={iconPt} alt="Bandeira do brasil" />
              <img src={iconEn} alt="Bandeira do Estado Unidos" />
              <img src={iconEs} alt="Bandeira da Espanha" />
            </div> */}
          </nav>
          <button className="close" onClick={openMenu}>
            {showMenu ? (
              <IoClose size={30} color="#fff" />
            ) : (
              <FaBars size={30} color="#fff" />
            )}
          </button>
        </Header>

        <Banner>
          <BannerWrapper>
            <h2>Tenha a excelência do ensino digital em Medicina do Esporte</h2>
            <h3>
              Seja membro do portal 100% digital que tornará você mais
              atualizado e qualificado na sua área profissional{' '}
            </h3>
            {/* <Link
              to="/app"
              style={{
                fontSize: 20,
                backgroundColor: '#21A4AC',
                padding: '20px 40px',
                borderRadius: 50,
                marginTop: 40,
                textDecoration: 'none',
                color: '#fff',
                fontWeight: 800,
              }}
            >
              Seja um HealthPlayer
            </Link> */}
            <LinkCTA
              href="https://portal.healthplay.pro/"
              target="_blank"
              rel="noreferrer"
              style={{
                fontSize: 20,
                fontWeight: 800,
                width: 300,
                padding: '30px 40px',
              }}
              onClick={closeMenu}
            >
              ACESSE O PORTAL
            </LinkCTA>

          </BannerWrapper>
        </Banner>
      </LandingBannerWrapper>

      {/* <OQueContainer>
        <h2>
          SOMOS UM <span>ECOSSISTEMA DE PROFISSIONAIS</span> INTERESSADOS E
          ATUANTES NA ÁREA DA MEDICINA DO ESPORTE
        </h2>
        <p>
          A HealthPlay PRO é feita de profissionais para profissionais. Nossa
          missão é fazer a curadoria dos temas mais importantes e desejados
          sobre Medicina do Esporte. Produzimos conteúdos com professores
          referência em suas respectivas áreas.
        </p>
      </OQueContainer> */}

      <SectionInicio id="inicio">
        {/* <ScrollAnimation animateIn="fadeIn" duration={3}> */}
        {/* <iframe src="https://player.vimeo.com/video/862557577?api=1&amp;byline=0&amp;portrait=0&amp;title=0&amp;background=1&amp;mute=1&amp;loop=1&amp;autoplay=0&amp;id=862557577#t=0s" width="980" height="520" style={{ width: '1659px', height: '933px', left: '-305px', top: '0', border: '0' }} ></iframe> */}
      </SectionInicio>

      <Beneficios ref={beneficiosRef}>
        <div className="titleMission">
          <img src={iconStar} alt="Stars" height={80} />
          <h2>Nosso objetivo é que você desempenhe em</h2>
          <h1>2 níveis</h1>
        </div>
        <div className="card">
          <span>nível 1</span>

          <h3>Atualização</h3>
          <p>
            Conteúdos de atualização na Biblioteca de Atualização e Laboratório
            de Artigos. Nossos professores transformam pesquisas densas - e
            muitas vezes em língua estrangeira - em explicações curtas e
            didáticas.
          </p>
        </div>
        <div className="card">
          <span>nível 2</span>

          <h3>Certificação</h3>
          <p>
            No nosso espaço de Cursos com Certificação é emitido um certificado
            que tem validade em todo território nacional.
          </p>
        </div>
      </Beneficios>

      <Planos>
        <h2>
          HealthPlay{' '}
          <span
            style={{
              backgroundColor: '#F2F3F4',
              padding: '5px 8px',
              borderRadius: 5,
              color: '#00171D',
            }}
          >
            PRO
          </span>{' '}
        </h2>
        <p>
        É para profissionais da Educação Física, fisioterapeutas e nutricionistas esportivos além de médicos que atuam na área do esporte
        </p>

        <p>
          <img src={ineexDesconto} alt="Desconto" height={180}/>
        </p>

        <CardWrapper id="planos" ref={planosRef}>
          <div className="card">
            <h4
              style={{
                background: '#C67575',
                width: '140px',
                borderRadius: '40px',
                padding: '10px 0',
                margin: 0,
              }}
            >
              recomendado
            </h4>
            <div className="color-header">
              <h5>Anual</h5>
              <div className="price">
                <p>
                  R$ 23,
                  <sup>25/mês</sup>
                </p>
              </div>
              <p>
              Plano mais tradicional da HealthPlay PRO! Aqui você &quot;ganha&quot; 2 meses. Por apenas R$ 23,25 ao mês você terá acesso:
              </p>
            </div>
            <p style={{ padding: '2em', marginTop: 60 }}>
              <li>Biblioteca da Atualização </li>
              <li>Laboratório de Artigos </li>
              <li>Cursos com Certificação</li>
              <li>Congressos gravados ao vivo</li>
              <li> Assista quando e onde quiser!</li>
            </p>
            <LinkCTA
              href="https://healthplayproineex.carrinho.app/one-checkout/ocmtb/13756404"
              target="_blank"
              rel="noreferrer"
              style={{
                fontSize: 16,
                fontWeight: 800,
                width: 250,
                textAlign: 'center',
                padding: '30px 40px',
                display: 'inline-flex',
                marginBottom: 0,
              }}
              onClick={closeMenu}
            >
              FAZER INSCRIÇÃO
            </LinkCTA>{' '}
          </div>
          <div className="card">
            <div className="color-header">
              <h5>Mensal</h5>
              <div className="price">
                <p>
                  R$ 29,
                  <sup>90/mês</sup>
                </p>
              </div>
              <p>
                Por apenas R$ 29,90
                ao mês você terá acesso:
              </p>
            </div>
            <p style={{ padding: '2em' }}>
              <li>Biblioteca da Atualização </li>
              <li>Laboratório de Artigos </li>
              <li>Cursos com Certificação</li>
              <li>Congressos gravados ao vivo</li>
              <li> Assista quando e onde quiser!</li>
            </p>
            <LinkCTA
              href="https://healthplayproineex.carrinho.app/one-checkout/ocmtb/13756074"
              target="_blank"
              rel="noreferrer"
              style={{
                fontSize: 16,
                fontWeight: 800,
                width: 250,
                textAlign: 'center',
                padding: '30px 40px',
                display: 'inline-flex',
                marginBottom: 0,
              }}
              onClick={closeMenu}
            >
              FAZER INSCRIÇÃO
            </LinkCTA>
          </div>
        </CardWrapper>

        {/* <Investimento>
        <div>
            <img
              src={investimento}
              alt="Quanto seria o investimento:R$ 5.000,00"
            />
          </div>
          <div className="textInvestment">
            <h2
              style={{
                marginTop: 40,
              }}
            >
              Quanto seria o <span>seu investimento</span> para ter tudo isso,
              no valor normal?
            </h2>
              <li>
                {' '}
                Biblioteca de Atualização: <strong>R$ 1.500</strong>{' '}
              </li>
              <li>
                {' '}
                Laboratório de Anúncios: <strong>R$ 1.000</strong>
              </li>
              <li>
                {' '}
                Cursos com Certificação: <strong> R$ 3.000</strong>
              </li>
              <b style={{ textDecoration: 'line-through' }}>
                Total: R$ 5.000,00
              </b>
              <p style={{ fontSize: '22px' }}>
                Porém aqui você investe apenas <b> R$ 39,90</b> em nosso plano
                mensal
              </p>
          </div>
        </Investimento> */}
      </Planos>
      <Servicos>
        <h2>
          O Portal HealthPlay PRO está em {' '}
          <span> constante crescimento e atualização</span> de conteúdos. Queremos que você acompanhe todas as principais tendências, por isso entregamos mensalmente os estudos relevantes mais recentes na área de Medicina do Esporte

        </h2>

        <ServicosWrapper>
          <CardServico>
            <CardImage>
              <img src={servico1} alt="Biblioteca de atualização" />
              <h3>BIBLIOTECA DE ATUALIZAÇÃO</h3>
            </CardImage>
            <CardServicoContent>
              Qualifique-se com aulas 100% objetivas! Com uma duração média de
              20 minutos em cada vídeo, aqui você encontra os conteúdos mais atuais e
              relevantes da área da Medicina do Esporte
            </CardServicoContent>
          </CardServico>
          <CardServico>
            <CardImage>
              <img src={servico2} alt="Cursos com certificação" />
              <h3>CURSOS COM CERTIFICAÇÃO</h3>
            </CardImage>
            <CardServicoContent>
              Fortaleça seu conhecimento e o seu currículo! Nossos cursos são
              desenvolvidos para você se aprofundar em conteúdos específicos da
              área da Medicina do Esporte.{' '}
            </CardServicoContent>
          </CardServico>
          <CardServico>
            <CardImage>
              <img src={servico3} alt="Laboratório de artigos" />
              <h3>LABORATÓRIO DE ARTIGOS</h3>
            </CardImage>
            <CardServicoContent>
              As publicações científicas de grande impacto são apresentadas pelo
              próprio autor ou por um especialista no assunto. Aqui não tem PDF,
              até os nossos artigos são em vídeo!
            </CardServicoContent>
          </CardServico>
          <CardServico>
            <CardImage>
              <img src={servico4} alt="Laboratório de artigos" />
              <h3> WORKSHOPS E CONGRESSOS</h3>
            </CardImage>
            <CardServicoContent>
              Nossos workshops e Congressos são gravados ao vivo e trazemos uma
              experiência imersiva sobre os assuntos apresentados pelos docentes
              em destaque no mercado.
            </CardServicoContent>
          </CardServico>
        </ServicosWrapper>
      </Servicos>
      <FAQ>
        <RiscoZero>
          <img
            src={riscoZero}
            alt="Risco zero - 7 dias de garantia"
            style={{ maxWidth: 350, padding: 30, textAlign: 'center' }}
          />
          <p>E tem mais: nossa garantia é</p>
          <h2>RISCO ZERO</h2>
          <p>
            Você tem 7 dias de garantia total. Se por algum motivo o conteúdo
            não te atender, basta solicitar o reembolso do seu investimento. Ou
            você fica feliz com o conteúdo, ou fica feliz com seu dinheiro de
            volta.
          </p>
        </RiscoZero>
        <h2>Perguntas frequentes</h2>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                COMO FUNCIONA OS 7 DIAS DE GARANTIA?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Todos os novos assinantes que desejarem cancelar a HealthPlay PRO em
              até 7 dias corridos, poderão realizar através do e-mail
              contato@healthplay.pro e o valor pago será estornado para o cartão
              de crédito, sem pegadinhas ou burocracia.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                POSSO ASSISTIR ÀS AULAS QUANTAS VEZES QUISER?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Sim, você poderá assistir as aulas quantas vezes quiser se a sua
              assinatura estiver ativa. Não existe limite.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                VOU RECEBER ALGUM DIPLOMA DE CONCLUSÃO?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Não. A HealthPlay PRO não é uma formação acadêmica curso com início, meio e fim. Somos uma
              comunidade com conteúdo ilimitado.A cada mês serão disponibilizados novos conteúdos, acessíveis na sessão &rdquo;Novidades&rdquo;. Porém dentro da HealthPlay PRO você encontra uma sessão chamada &rdquo;Cursos com Certificação&rdquo;, onde ao realizar a conclusão de cada curso, você terá um certificado de participação – exclusivo para acadêmicos e profissionais da saúde (Nutrição, Fisioterapia, Educação Física e Medicina)
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                EXISTE ALGUM CRONOGRAMA DE AULAS?{' '}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Os assuntos serão escolhidos de acordo com as temáticas
              relevantes, as solicitações da comunidade e as novidades
              científicas.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                COMUNIDADE DESTINADA A QUAL PÚBLICO?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              A HealthPlay PRO é destinada especialmente aos profissionais da saúde.
              No entanto, por acreditarmos que o acesso ao conhecimento deva ser
              universal, qualquer pessoa pode se tornar um HealthPlayer.
              <br/>Observação: a sessão “Cursos com Certificação” é exclusiva para acadêmicos e profissionais da saúde (Nutrição, Fisioterapia, Educação Física e Medicina).
            </AccordionItemPanel>
          </AccordionItem>
          <p
            style={{
              color: '#fff',
              opacity: '0.8',
              textAlign: 'center',
              fontWeight: 400,
            }}
          >
            Ainda possiu alguma dúvida?
          </p>

          <div
            style={{
              backgroundColor: '#25d366',
              padding: '15px',
              color: 'white',
              borderRadius: 30,
              textAlign: 'center',
            }}
          >
            <a
              href="https://api.whatsapp.com/send?phone=5551997982266"
              target="_blank"
              rel="noopener noreferrer"
              color="#fff"
              style={{ textDecoration: 'none' }}
            >
              <FaWhatsapp
                size={30}
                color="#fff"
                style={{ verticalAlign: 'middle' }}
              />{' '}
              <span
                style={{ fontWeight: 500, fontSize: '20px', color: '#fff' }}
              >
                WhatsApp
              </span>
            </a>
          </div>
        </Accordion>
        <div style={{ maxWidth: 500, textAlign: 'center', color: '#fff' }}>
          <h2>Sobre nós</h2>
          <p
            style={{
              fontWeight: 300,
              fontSize: 14,
              lineHeight: '1.6',
              textAlign: 'center',
              color: '#F2F3F4',
              letterSpacing: '0.36px',
            }}
          >
            Surgimos na era digital, focados em alternativas em soluções de
            ensino na saúde para clientes digitais.
          </p>

          <p
            style={{
              fontWeight: 300,
              fontSize: 14,
              lineHeight: '1.6',
              textAlign: 'center',
              color: '#F2F3F4',
              letterSpacing: '0.36px',
            }}
          >
            Acreditamos que a educação, assim como a saúde, são verdadeiros
            pilares para a construção de uma sociedade mais saudável.
            Desenvolvemos conteúdos digitais focados na área da saúde para
            empresas e instituições de ensino e, através do nosso portal, para
            os profissionais que atuam na área da Medicina do Esporte a qualquer
            hora do dia, seja no computador, no tablet ou no smartphone.
          </p>
        </div>
      </FAQ>

      {/* <Novidades ref={novidadesRef}>
        <div className="inner-container">
          <h2>Novidades</h2>
          <p>
            Se inscreva para receber notícias em primeira mão sobre o nosso
            lançamento!
          </p>

          <div className="input-container">
            <input
              ref={newsletterInputRef}
              type="email"
              name="email"
              placeholder="Digite seu melhor e-mail"
            />
            <button onClick={handleNewsletterSubmit} disabled={loading}>
              {loading ? 'enviando...' : 'enviar'}
            </button>
          </div>
        </div>
      </Novidades> */}
      <Footer />
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        contentStyle={{
          width: '60%',
          maxWidth: 640,
          height: '70%',
          maxHeight: 360,
          backgroundColor: 'transparent',
        }}
      >
        {/* <iframe
          className="playerBox"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="YouTube video player"
          src="https://www.youtube.com/embed/ZqgLdrMb7X4?modestbranding=1&amp;autoplay=0&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;version=3&amp;playerapiid=iframe_YTP_1618450523061&amp;origin=http%3A%2F%2Fhealthplay.pro&amp;allowfullscreen=true&amp;wmode=transparent&amp;iv_load_policy=3&amp;playsinline=1&amp;mute=0&amp;html5=1&amp;widgetid=1"
          frameBorder="0"
          width="100%"
          height="100%"
        ></iframe> */}
      </Modal>
      <CookieConsent
        location="bottom"
        buttonText="Sim, aceito"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#2B373B' }}
        buttonStyle={{
          color: '#fff',
          backgroundColor: primaryColor,
          fontSize: '13px',
        }}
        expires={150}
        debug={true}
      >
        <p style={{ fontSize: 13 }}>
          Utilizamos cookies para que você tenha a melhor experiência do nosso
          site. Por sua visita contínua ao nosso site, sem alterar suas
          configurações, você concorda com o uso de cookies da HealthPlay.
        </p>
      </CookieConsent>
    </>
  )
}

export default LandingPage
