import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  padding-bottom: 60%;
  /* padding-bottom: 40%; */
  height: 0;
  overflow: hidden;
  width: 100%;
`

export const VideoContainer = styled.div`
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const ThumbContainer = styled.div<{ image: string }>`
  height: 400px;
  width: 100%;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 690px) {
    height: 300px;
  }

  @media screen and (max-width: 425px) {
    height: 200px;
  }
`

export const PlayIcon = styled.div`
  height: 208px;
  width: 208px;
  cursor: pointer;
`
