import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Container } from './styles'

interface IProps {
  name: string
  checkboxLabel?: string
}

// eslint-disable-next-line no-undef
type IInputProps = IProps & JSX.IntrinsicElements['input']

const Input: React.FC<IInputProps> = ({ name, checkboxLabel, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  return (
    <>
      <Container style={{ display: rest.type === 'hidden' ? 'none' : 'flex' }}>
        <input ref={inputRef} defaultValue={defaultValue} {...rest} />
      </Container>
      {error && (
        <small
          style={{
            color: '#fa4545',
            marginTop: -8,
            marginBottom: 10,
            fontSize: 12,
          }}
        >
          {error}
        </small>
      )}
    </>
  )
}

export default Input
