import styled from 'styled-components'

export const Container = styled.label`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }

  span {
    padding: 0.7rem;
    margin-left: 1rem;
    background-color: #21a4ac;
    border-radius: 8px;
    color: #fff;
    transition: 400ms ease-in-out;

    :hover {
      color: #21a4ac;
      background-color: #fff;
    }
  }
`
