import styled from 'styled-components'

export const Container = styled.label`
  width: 100%;
  align-items: center;
  justify-content: space-between;

  input:focus {
    outline: 2px solid #21a4ac;
  }

  input {
    width: 100%;
    border: none;
    margin: 0;
    line-height: 1.5;
    border-radius: 5px;
    border: 0.5px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    -webkit-appearance: none;

    ::placeholder {
      color: #aaa;
    }

    &[type='file'] {
      display: none;
    }
  }

  button {
    padding: 0;
    margin-left: 5px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`
