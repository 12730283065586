import styled from 'styled-components'

export const Container = styled.div`
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  } */
`
