import React from 'react'
import { useHistory } from 'react-router'
import { IPricingCard } from 'screens/Pricing/SelectPlan'
import { Container } from './styles'

interface IPricingCardProps {
  value: IPricingCard
  hideClick?: boolean
}

const PricingCard: React.FC<IPricingCardProps> = ({
  value,
  hideClick,
  ...rest
}) => {
  const history = useHistory()

  return (
    <Container {...rest}>
      <div className="color-header">
        <h4>{value.name}</h4>
        <h3>{value.period}</h3>
        <div className="price">
          <span>
            <sup>R$</sup>
            {value.price?.toFixed(2).replace('.', ',')}
          </span>
          {value.installments > 1 && (
            <div className="parcel">
              <small>Parecelado em até {value.installments}x</small>
              <small>{value.monthlyPrice}</small>
            </div>
          )}
        </div>
      </div>
      <p style={{ padding: '2em' }}>
        {value.description?.map((item) => (
          <li key={Math.random() + Date.now()}>{item}</li>
        ))}
      </p>
      {!hideClick && (
        <button
          onClick={() => {
            localStorage.setItem(
              '@HealthPlay:PricingCard',
              JSON.stringify(value),
            )
            history.push('/app/planos/cadastro-dos-dados', { selected: value })
          }}
        >
          Quero Assinar!
        </button>
      )}
    </Container>
  )
}

export default PricingCard
