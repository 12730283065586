import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

let logoutCallback: (() => void) | undefined
class Api {
  public baseURL: string
  public api: AxiosInstance

  constructor(config?: AxiosRequestConfig) {
    const envBaseUrl = process.env.REACT_APP_API_BASE_URL

    switch (process.env.NODE_ENV) {
      case 'development':
        this.baseURL = envBaseUrl || 'http://localhost:3333'
        break
      default:
        this.baseURL = envBaseUrl || 'https://healthplay-api.herokuapp.com'
    }

    this.api = axios.create({
      baseURL: this.baseURL,
      ...config,
    })
  }
}
const api = new Api().api

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    error = error || {}
    if (!error || !error.response || !error.response.data) {
      error.errorCode = 'NO_CONNECTION'
    } else {
      error.errorCode = error.response.data.code
      error.message = error.response.data.message
      error.httpStatus = error.response.status
      error.action = error.response.data.action
    }

    if (
      error.action === 'logout' ||
      error.message === 'Você não tem permissão para isto'
    ) {
      console.log('LOGGGING OUT')
      logoutCallback?.()
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  },
)

export function setApiAuthToken(token?: string) {
  if (!token || token === 'null' || token === 'undefined') {
    delete api.defaults.headers.authorization
  } else {
    api.defaults.headers.authorization = token
  }
}
export function setLogoutCallback(callback: () => void) {
  logoutCallback = callback
}

const storagetoken = localStorage.getItem('@HealthPlay:token')
if (storagetoken) {
  setApiAuthToken(storagetoken)
}
export default api
