/* eslint-disable no-restricted-globals */
import React from 'react'
import Vimeo, { TimeUpdateEvent } from '@u-wave/react-vimeo'
import { Container, ThumbContainer, PlayIcon } from './styles'
import { useVideo } from 'hook/Vimeo'
import { useLogin } from 'hook/Login'
import { useAuth } from 'hook/Auth'
import { useToast } from 'hook/Toast'

export interface IVideoPlayerProps {
  onTimeUpdate?: (event: TimeUpdateEvent) => void
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ onTimeUpdate }) => {
  const { type, url } = useVideo()
  const { setOpen } = useLogin()
  const { user } = useAuth()
  const { addToast } = useToast()

  return (
    <Container>
      {type === 'link' ? (
        <Vimeo
          video={`https://player.vimeo.com/video/${
            (url || '').replace('https://vimeo.com/', '').split('/')[0]
          }?h=${
            (url || '').replace('https://vimeo.com/', '').split('/')[1]
          }&app_id=122963`}
          responsive
          onTimeUpdate={onTimeUpdate}
        />
      ) : (
        <ThumbContainer image={url || ''}>
          <PlayIcon
            onClick={() => {
              if (user) {
                addToast({
                  type: 'info',
                  title:
                    'Ops!! Você não tem acesso a esse material. Compre este curso ou veja os benefícios de ser assinante',
                  time: 5000,
                })
              } else setOpen()
            }}
          />
        </ThumbContainer>
      )}
    </Container>
  )
}

export default VideoPlayer
