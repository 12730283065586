import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { Info } from './styles'
interface IWhatAreYouPurchasingProps {
  expirationDays?: number
}

const WhatAreYouPurchasing: React.FC<IWhatAreYouPurchasingProps> = ({
  expirationDays,
}) => {
  return (
    <Info>
      <div>
        <strong>O que você está comprando?</strong>
        <p>
          Parabéns! Você está se tornando um HealthPlayer com direito ao acesso
          a toda plataforma.
        </p>
        <p>Principais beneficios que você vai ter:</p>
        <ul>
          <li>
            <FaCheck size={15} /> Acesso ilimitado a todo o conteúdo do nosso
            portal a qualquer momento do dia, seja no computador, no tablet ou
            smartphone.
          </li>
          {expirationDays ? (
            <li>
              <FaCheck size={15} /> Acesso liberado por {expirationDays} dias.
            </li>
          ) : null}
        </ul>
      </div>
    </Info>
  )
}

export default WhatAreYouPurchasing
