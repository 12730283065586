/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react'
import { Section } from 'styles'
import Loading from 'components/Loading'
import VideoAulaScroll from 'components/VideoAulaScroll'
import CourseAreaScroll from 'components/CourseAreaScroll/'
import HeaderList from 'components/HeaderList'
import api from 'services/api'
import {
  // IAllCategories,
  IAulaHotCarousel,
  ICourseTypes,
  // IListAula,
  IHomeCourse,
} from '@types'

interface ICateg {
  id?: string
  name: string
  isSub?: boolean
}

const VideoList: React.FC<{ type: ICourseTypes }> = ({ type }) => {
  // const [banners, setBanners] = useState<IBanners>({} as IBanners)
  const [category] = useState<ICateg | undefined>({ name: 'hot' })
  // const [categories, setCategories] = useState<ICateg[]>([])
  // const [courses, setCourses] = useState<IListAula[]>([])

  const [hot, setHot] = useState<IAulaHotCarousel[]>([])
  // const [gratuitos, setGratuitos] = useState<IHomeCourse[]>([])
  const [novidades, setNovidades] = useState<IHomeCourse[]>([])

  // const [all, setAll] = useState<IAllCategories[]>([])
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       const { data } = await api.get(`/${type}s/banners`)
  //       setBanners(data)
  //     } catch (err) {
  //       console.error('Ocorreu algum erro, recarregue a página')
  //       console.table(err)
  //     }
  //   })()
  //   // eslint-disable-next-line
  // }, [])

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        setLoading(true)

        // if (loadCateg) {
        //   const { data: categ } = await api.get('/categories', {
        //     params: { limit: 5 },
        //   })
        //   setCategories(categ)
        //   setLoadCateg(false)
        // }

        switch (category?.name) {
          case 'hot':
            const { data } = await api.get(`/${type}s/em-alta`)
            setHot(data.list)
            setNovidades(data.novidades)
            // setGratuitos(data.free)
            // setAll([])
            // setCourses([])
            break
          case 'all':
            // const resAll = await api.get('/categories/all')
            // setAll(resAll.data)
            setHot([])
            // setCourses([])
            break
          default:
            // const res = await api.get(`/${type}s`, {
            //   params: {
            //     [category?.isSub ? 'subCategory' : 'category']: category?.id,
            //   },
            // })
            // setCourses(res.data)
            setHot([])
            // setAll([])
            break
        }
      } catch (err) {
        alert('Ocorreu algum erro, recarregue a página')
      } finally {
        setLoading(false)
      }
    }

    loadData()
    // eslint-disable-next-line
  }, [category])

  return (
    <>
      {/* <BannerCarousel images={banners.banners} /> */}
      <HeaderList type={type} />
      {/* <CategoryButtonContainer>
        <CategoryButton
          onClick={() => setCategory({ name: 'hot' })}
          active={category?.name === 'hot'}
        >
          Em alta
        </CategoryButton>
        {categories.map((v) => (
          <CategoryButton
            key={v.id}
            onClick={() => setCategory({ id: v.id, name: v.name })}
            active={category?.name === v.name}
          >
            {v.name}
          </CategoryButton>
        ))}
        <CategoryButton
          onClick={() => setCategory({ name: 'all' })}
          active={category?.name === 'all'}
        >
          Todas as categorias
        </CategoryButton>
      </CategoryButtonContainer> */}
      <Section>
        {/* <SectionTitle>Video{type}s</SectionTitle> */}
        {hot.length > 0 &&
          hot.map((courseArea, idx) => (
            <VideoAulaScroll
              key={idx}
              name={''}
              courses={courseArea.courses}
              type={type}
            />
          ))}
        {novidades.length > 0 && (
          <CourseAreaScroll name="Novidades" courses={novidades} />
        )}
        {/* {gratuitos.length > 0 && (
          <CourseAreaScroll name="Gratuitos" courses={gratuitos} />
        )} */}
        {/* {gratuitos.length > 0 &&
          hot.map((courseArea, idx) => (
            <VideoAulaScroll
              key={idx}
              name={''}
              courses={courseArea}
              type={type}
            />
          ))} */}

        {/* {all.length > 0 &&
          all.map((courseArea) => (
            <>
              <SubCategoryButton
                style={{ color: '#333', fontSize: 25, fontWeight: 700 }}
                onClick={() =>
                  setCategory({ id: courseArea.id, name: courseArea.name })
                }
              >
                {courseArea.name}
              </SubCategoryButton>
              <SubCategoryButtonWrapper>
                {courseArea.subCategories.map((c) => (
                  <SubCategoryButton
                    key={c.id}
                    onClick={() =>
                      setCategory({ id: c.id, name: c.name, isSub: true })
                    }
                  >
                    {c.name}
                  </SubCategoryButton>
                ))}
              </SubCategoryButtonWrapper>
            </>
          ))}
        {courses.length > 0 && (
          <>
            <CategoryAreaTitle>{category?.name}</CategoryAreaTitle>
            <CoursesSection>
              {courses.map((courseArea, idx) => (
                <CourseItem key={idx} course={courseArea} type={type} />
              ))}
            </CoursesSection>
          </>
        )} */}
        {/* <AdvertisingBanner src={banners.ad} style={{ marginTop: 40 }} /> */}
      </Section>
      {loading && <Loading show={loading} />}
    </>
  )
}

export default VideoList
