import React, { createContext, useCallback, useContext, useState } from 'react'
interface IVimeoState {
  type: 'link' | 'thumb'
  url: string
  id: string
}
interface IVimeoContext {
  type?: 'link' | 'thumb'
  url?: string
  id?: string
  setVideo: (values: IVimeoState) => void
}

const VimeoContext = createContext({} as IVimeoContext)

const VimeoProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IVimeoState | undefined>(() => {
    const selectedVideo = localStorage.getItem('@HealthPlay:selectedVideo')

    if (selectedVideo) return JSON.parse(selectedVideo)
  })

  const setVideo = useCallback((values: IVimeoState) => {
    setData(values)
    localStorage.setItem('@HealthPlay:selectedVideo', JSON.stringify(values))
  }, [])

  return (
    <VimeoContext.Provider value={{ ...data, setVideo }}>
      {children}
    </VimeoContext.Provider>
  )
}

function useVideo(): IVimeoContext {
  const context = useContext(VimeoContext)
  if (!context) throw new Error('useVideo must be used within an VimeoProvider')
  return context
}

export { useVideo, VimeoProvider }
