import styled from 'styled-components'
// import { primaryColor } from 'styles/colors'
import { CleanLink as CleanLinkBase } from 'styles/'

const responsiveTrigger = 800

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px 5% 15px 5%;
  background-color: #00171d;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    font-size: 12px;
  }

  @media (max-width: ${responsiveTrigger}px) {
    flex-direction: column;

    padding: 20px 2% 5px 2%;
  }
`

export const FooterItem = styled.div`
  display: flex;
  font-weight: bold;
  @media (max-width: ${responsiveTrigger}px) {
    margin: 12px 0;
  }
`

export const SocialMediaIcon = styled.img`
  height: 32px;
  object-fit: contain;
  margin: 0 16px;
  cursor: pointer;
`

export const ContactIcon = styled.img`
  height: 26px;
  object-fit: contain;
  margin: 10px 10px;
  cursor: pointer;
`

export const CleanLink = styled(CleanLinkBase)`
  font-size: 14px;
  margin-bottom: 12px;

  :hover {
    text-decoration: underline;
  }
`
