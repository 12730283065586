import React, { useCallback, useState } from 'react'
import Modal from 'components/Modal'
import { FaTimes } from 'react-icons/fa'
import ReactStars from 'react-star-rating-component'
import { Container, Teacher } from './styles'
import { useToast } from 'hook/Toast'
import { useAuth } from 'hook/Auth'
import api from 'services/api'
import { ICourseTypes } from '@types'

interface IModalReviewProps {
  show: boolean
  onClose: () => void
  reload?: () => void
  courseId: string
  courseName: string
  type: ICourseTypes
  teacher: {
    name: string
    occupation: string
    image: string
  }
}

const ModalReview: React.FC<IModalReviewProps> = ({
  show,
  onClose,
  reload,
  courseId,
  type,
  courseName,
  teacher,
}) => {
  const [loading, setLoading] = useState(false)
  const [stars, setStars] = useState(0)
  const [text, setText] = useState('')

  const { addToast } = useToast()
  const { token } = useAuth()

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(() => true)
    try {
      if (stars === 0) {
        addToast({
          type: 'error',
          title: 'A avaliação minima é 1 estrela',
        })
        return
      }
      if (text.length <= 0) {
        addToast({
          type: 'error',
          title: 'A avaliação é obrigatória',
        })
        return
      }

      await api.post(
        `/${type}s/setReview`,
        { text, stars, idVideo: courseId },
        { headers: { authorization: token } },
      )

      addToast({
        type: 'success',
        title: 'Avaliação registradada com sucesso',
      })

      // setTimeout(() => window.location.reload(), 500)
      onClose()
      reload?.()
    } catch (err) {
      addToast({
        type: 'error',
        title: 'OPS...',
        description:
          err?.response?.data?.message || 'Ocorreu algum erro, tente novamente',
      })
    } finally {
      setLoading(() => false)
    }
  }, [addToast, stars, text, courseId, token, type, onClose, reload])

  return (
    <Modal isOpen={show} contentStyle={{ borderRadius: 10 }} onClose={onClose}>
      <Container>
        <button className="close" onClick={onClose}>
          <FaTimes color="#999" size={25} />
        </button>
        <h2>{courseName}</h2>
        <Teacher>
          <img src={teacher.image} alt={teacher.name} />
          <div>
            <strong>{teacher.name}</strong>
            <span>{teacher.occupation}</span>
          </div>
        </Teacher>
        <h4>Selecione a nota</h4>
        <div className="star-container">
          <ReactStars
            name="rate"
            starCount={5}
            value={stars}
            onStarClick={setStars}
            emptyStarColor="#333"
          />
        </div>
        <h4>Nos diga o que você achou do curso</h4>
        <textarea
          name="description"
          rows={6}
          defaultValue={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <button className="finish" disabled={loading} onClick={handleSubmit}>
          AVALIAR
        </button>
      </Container>
    </Modal>
  )
}

export default ModalReview
