import styled from 'styled-components'
import { Form as Unform } from '@unform/web'
import InputComponent from 'components/Input'
import Button from 'components/Button'

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const Right = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #ddd;

@media (max-width: 700px) {
  border: none;
  border-top: 2px solid #ddd;
  margin-top: 30px;
}
`

export const FinishedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 350px;
  color: #aaa;
  padding: 0 10px;
  margin: 40px 0;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 50%;
    border: 2px solid lightgreen;
    color: lightgreen;
    margin-bottom: 10px;
  }

  strong {
    color: #333;
    text-align: center;
  }
`

export const FinishButton = styled(Button)`
  border-radius: 5px;
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  height: 60px;
  margin-top: 50px;
`

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 90%;
  max-width: 500px;

  .flex {
    display: flex;
    justify-content: space-between;

    .item {
      flex: 1;
    }
  }
`

export const Label = styled.span`
  font-weight: 600;
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
`

export const Input = styled(InputComponent)`
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  margin-bottom: 10px;
  /* padding: 10px; */

  ::placeholder {
    color: #aaa;
  }
`
