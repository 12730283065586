import styled from 'styled-components'

export const Container = styled.label`
  width: 100%;
  border: none;
  border-radius: none;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: fit-content;
    margin-right: 0.5rem;
    border: none;
    
    ::placeholder {
      color: #aaa;
    }
  }

  span {
    color: #333;
  }
`
