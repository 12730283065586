import React, { createContext, useContext, useCallback, useState } from 'react'
import { v4 } from 'uuid'
import ToastContainer from '../components/ToastContainer'

interface IToastContextData {
  addToast(messages: Omit<IToastMessage, 'id'>): void
  removeToast(id: string): void
}

export interface IToastMessage {
  id: string
  type?: 'success' | 'error' | 'info'
  title: string
  description?: string
  time?: number
}

const ToastContext = createContext<IToastContextData>({} as IToastContextData)

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IToastMessage[]>([])

  const addToast = useCallback(
    ({ type, title, description, time }: Omit<IToastMessage, 'id'>) => {
      const id = v4()

      const toast = {
        id,
        type,
        title,
        description,
        time,
      }

      setMessages((oldMessages) => [...oldMessages, toast])
    },
    [],
  )

  const removeToast = useCallback((id: string) => {
    setMessages((oldMessages) =>
      oldMessages.filter((message) => message.id !== id),
    )
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer show={messages.length > 0} messages={messages} />
    </ToastContext.Provider>
  )
}

function useToast(): IToastContextData {
  const context = useContext(ToastContext)
  if (!context) throw new Error('useToast must be within a ToastProvider')
  return context
}

export { useToast, ToastProvider }
