import React from 'react'
import { Container, CardWrapper } from './styles'
import VideoCard from 'components/VideoCard/'
// import { MdAccessTime } from 'react-icons/md'

import { ICourseTypes, IListAula } from '@types'

interface CourseItemProps extends React.InputHTMLAttributes<HTMLDivElement> {
  course: IListAula
  type?: ICourseTypes
  totalSlides?: number
}

const CourseItem: React.FC<CourseItemProps> = ({
  course,
  type,
  totalSlides,
  ...rest
}) => {
  // const [levelIcons] = useState(() => {
  //   const level = []
  //   if (course.rate) {
  //     for (let i = 0; i < 5; i++) {
  //       level.push(<LevelIcon key={i} active={i < course.rate} />)
  //     }
  //     return level
  //   }
  //   return 0
  // })

  return (
    <Container {...rest}>
      <CardWrapper className={totalSlides && totalSlides > 1 ? 'vertical' : ''}>
        <VideoCard
          style={{ height: 188, width: 310 }}
          coverImageUrl={course.image}
          url={`/app/${type}/${course.slug}`}
          className="video"
          publish={course.publish}
        />
        {course.teacher && (
          <div className="info">
            <h5>{course.name}</h5>
            {/* <LevelContainer>{course.rate > 0 && levelIcons}</LevelContainer> */}

            <h6>{course.teacher.name}</h6>
            {course.short_description.match(/^em\sbreve$/i) && (
              <p
                style={
                  course.short_description.match(/^em\sbreve$/i)
                    ? {
                        color: '#979797',
                        fontWeight: 600,
                        fontSize: 12,
                        backgroundColor: '#eeeeee',
                        borderRadius: 10,
                        padding: 8,
                        width: '80px',
                      }
                    : undefined
                }
              >
                {course.short_description}
              </p>
            )}
            {/* <p
              style={
                course.short_description.match(/^em\sbreve$/i)
                  ? {
                      color: '#6b6b6b',
                      fontWeight: 300,
                      fontSize: 14,
                      backgroundColor: '#eeeeee',
                      borderRadius: 10,
                      padding: 8,
                      width: '100px',
                    }
                  : undefined
              }
            >
              {course.short_description}
            </p> */}
            {/* <span>
              <MdAccessTime style={{ marginRight: 4 }} />
              {course.duration} min
            </span> */}
          </div>
        )}
        {/* <div className="bottom-title">
          <CourseTitle>{course.name}</CourseTitle>
        </div> */}
      </CardWrapper>
    </Container>
  )
}

export default CourseItem
