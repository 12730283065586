import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { useHistory, withRouter } from 'react-router'
import { Container, Wrapper, Line, Dot1, Dot2, Dot3 } from './styles'

const BreadCrumb = withRouter(({ location: { pathname } }: any) => {
  const history = useHistory()

  return (
    <Container>
      {!pathname.includes('selecione-o-plano') && (
        <button
          className="back"
          onClick={() => {
            if (pathname.includes('cadastro')) {
              localStorage.removeItem('@HealthPlay:PricingCard')
              localStorage.removeItem('@HealthPlay:PricingInfo')
              history.push('/app/planos/selecione-o-plano')
            }
            if (pathname.includes('finalizacao')) {
              localStorage.removeItem('@HealthPlay:PricingCard')
              localStorage.removeItem('@HealthPlay:PricingInfo')
              history.push('/app/planos/cadastro-dos-dados')
            }
          }}
        >
          <FaChevronLeft size={30} />
        </button>
      )}
      <Wrapper>
        <Line />
        <Dot1
          className="dot"
          active
          onClick={() => {
            localStorage.removeItem('@HealthPlay:PricingCard')
            localStorage.removeItem('@HealthPlay:PricingInfo')
            history.push('/app/planos/selecione-o-plano')
          }}
        >
          <span>Seleção de plano</span>
        </Dot1>
        <Dot2
          className="dot"
          active={
            pathname.includes('cadastro-dos-dados') ||
            pathname.includes('finalizacao')
          }
          onClick={() => {
            if (
              pathname.includes('cadastro-dos-dados') ||
              pathname.includes('finalizacao')
            ) {
              localStorage.removeItem('@HealthPlay:PricingCard')
              localStorage.removeItem('@HealthPlay:PricingInfo')
              history.push('/app/planos/cadastro-dos-dados')
            }
          }}
        >
          <span>Informações de compra</span>
        </Dot2>
        <Dot3 className="dot" active={pathname.includes('finalizacao')}>
          <span>Confirmação</span>
        </Dot3>
      </Wrapper>
    </Container>
  )
})

export default BreadCrumb
