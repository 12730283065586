import { ICourseData } from '@types'
import { addDays, format } from 'date-fns'
import React from 'react'
import { formatCurrencyBRL } from 'utils/formatUtils'
import { Container } from './styles'

interface IProductPricingCardProps {
  item: ICourseData
  // hideClick?: boolean
}

const ProductPricingCard: React.FC<IProductPricingCardProps> = ({
  item,
  // hideClick,
  ...rest
}) => {
  // const history = useHistory()
  const expirationDate = addDays(new Date(), item.expiration_in_days || 0)
  return (
    <Container {...rest}>
      <div className="color-header">
        <h4>{item.name}</h4>
        <h3>{item.expiration_in_days} dias</h3>
        <div className="price">
          <span>
            <sup>R$</sup>
            {formatCurrencyBRL(item.price)}
          </span>
        </div>
      </div>
      <p style={{ padding: '2em' }}>{item.short_description}</p>
      {item.expiration_in_days ? (
        <p style={{ padding: '2em' }}>
          Expira em: {format(expirationDate, 'dd/MM/yyyy')}
        </p>
      ) : null}

      {/* {!hideClick && (
        <button
          onClick={() => {
            localStorage.setItem(
              '@HealthPlay:PricingCard',
              JSON.stringify(value),
            )
            history.push('/app/planos/cadastro-dos-dados', { selected: value })
          }}
        >
          Quero Assinar!
        </button>
      )} */}
    </Container>
  )
}

export default ProductPricingCard
