import React from 'react'
import { Route } from 'react-router-dom'

import Home from 'screens/Home'
import VideoList from 'screens/VideoList'
import Detail from 'screens/Detail'
import Register from 'screens/Register'
import Profile from 'screens/Profile'
import Pricing from 'screens/Pricing'
import termoDeUso from 'assets/pdf/Termos_de_Uso.pdf'
import politicaDePrivacidade from 'assets/pdf/Politica_de_Privacidade.pdf'

import PDFEmbedded from 'components/PDFEmbedded'
import LandingPage from 'screens/LandingPage'
import LandingPageBodyTech from 'screens/LandingPageBodyTech'
import LandingPageIneex from 'screens/LandingPageIneex'
import TestCardHash from 'components/TestCardHash'
// import VideoCourseList from 'screens/VideoCourseList'
import Checkout from 'screens/Checkout'
import { AffiliatedLink } from 'screens/AffiliatedLink'

// const PDFEmbedded = lazy(() => import('components/PDFEmbedded'))

const Routes: React.FC = () => {
  return (
    <>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/app" component={Home} />
      <Route exact path="/app/cadastre-se" component={Register} />
      <Route exact path="/convite-novo-cadastro" component={AffiliatedLink} />
      <Route exact path="/app/perfil" component={Profile} />
      <Route exact path="/generate_test_card_hash" component={TestCardHash} />
      <Route exact path="/parceiro/bodytech" component={LandingPageBodyTech} />
      <Route exact path="/parceiro/ineex" component={LandingPageIneex} />
      <Route
        exact
        path="/app/video-artigos"
        render={() => <VideoList type="artigo" />}
      />
      <Route
        exact
        path="/app/video-aulas"
        render={() => <VideoList type="aula" />}
      />
      <Route
        exact
        path="/app/video-clinicas"
        render={() => <VideoList type="clinica" />}
      />
      <Route
        exact
        path="/app/video-cursos"
        render={() => <VideoList type="curso" />}
        // render={() => <VideoCourseList type="course" />}
      />
      {/* <Route exact path="/app/webinar/:slug?" component={Webinar} /> */}
      <Route
        exact
        path="/app/aula/:slug"
        render={() => <Detail type="aula" />}
      />
      {/* <Route
        exact
        path="/app/course/:slug"
        render={() => <Detail type="course" />}
      /> */}
      <Route
        exact
        path="/app/curso/:slug"
        render={() => <Detail type="curso" />}
      />
      <Route
        exact
        path="/app/artigo/:slug"
        render={() => <Detail type="artigo" />}
      />
      {/* <Route
        exact
        path="/app/clinica/:slug"
        render={() => <Detail type="clinica" />}
      /> */}
      {/* <Route exact path="/app/clinica/prova/:id" component={Test} /> */}
      <Route path="/app/planos" component={Pricing} />
      <Route path="/app/checkout" component={Checkout} />

      <Route
        path="/app/termos-de-uso"
        component={() => <PDFEmbedded pdf={termoDeUso} />}
      />
      <Route
        path="/app/politica-de-privacidade"
        component={() => <PDFEmbedded pdf={politicaDePrivacidade} />}
      />
    </>
  )
}

export default Routes
