export default (file: File): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const result = reader?.result as string
      resolve(result.replace(/^data:.+;base64,/, ''))
    }
    reader.onerror = error => reject(error)
  })
