import React, { createContext, useCallback, useContext, useState } from 'react'

interface ILoginContext {
  open: boolean
  setOpen: (value?: boolean) => void
}

const LoginContext = createContext({} as ILoginContext)

const LoginProvider: React.FC = ({ children }) => {
  const [data, setData] = useState(false)

  const setOpen = useCallback((value?: boolean) => {
    setData((old) => (typeof value !== 'undefined' ? value : !old))
  }, [])

  return (
    <LoginContext.Provider value={{ open: data, setOpen }}>
      {children}
    </LoginContext.Provider>
  )
}

function useLogin(): ILoginContext {
  const context = useContext(LoginContext)
  if (!context) throw new Error('useLogin must be used within an LoginProvider')
  return context
}

export { useLogin, LoginProvider }
