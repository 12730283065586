import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  /* min-height: 300px; */
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const CourseTitle = styled(Link)`
  color: #292929;
  font-size: 1rem;
  font-weight: 800;
  margin: 8px 0 2px 8px;
  text-decoration: none;
`

export const CourseTeacherName = styled.div`
  color: #8c8c8c;
  font-size: 0.9rem;
  margin-left: 8px;
  display: flex;
  align-items: center;
`

export const CourseDetails = styled.div`
  color: #8c8c8c;
  font-size: 0.7rem;
  margin-left: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 10px;
  opacity: 0.5;
`
