import styled from 'styled-components'
import { primaryColor } from 'styles/colors'
import { darken, lighten } from 'polished'

const BaseButton = styled.button`
  min-width: 120px;
  height: 40px;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  :disabled {
    background-color: #ddd;
    cursor: not-allowed;

    :hover {
      background-color: #ddd;
    }
  }

  @media screen and (max-width: 605px) {
    margin-top: 30px;
    height: 55px;
  }
`

export const Primary = styled(BaseButton)`
  background-color: ${primaryColor};
  color: white;
  border: none;
  transition: all 0.4s;

  :active {
    background-color: ${darken(0.1, primaryColor)};
  }

  :hover {
    background-color: ${darken(0.1, primaryColor)};
  }
`
export const Secondary = styled(BaseButton)`
  background-color: white;
  color: ${primaryColor};
  border: 1px solid ${primaryColor};
  transition: all 0.4s;

  :active {
    background-color: ${lighten(0.4, primaryColor)};
  }

  :hover {
    background-color: ${lighten(0.4, primaryColor)};
  }
`
export const Tertiary = styled(BaseButton)`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transition: all 0.4s;

  :active {
    background-color: ${lighten(0.2, primaryColor)};
  }

  :hover {
    background-color: ${lighten(0.2, primaryColor)};
  }
`

export const Orange = styled(BaseButton)`
  background-color: transparent;
  color: ${primaryColor};
  border: 1px solid ${primaryColor};
  transition: all 0.4s;

  :active {
    background-color: ${lighten(0.2, '#292929')};
    color: #fff;
    border-color: #292929;
    background-color: #292929;
  }

  :hover {
    background-color: ${lighten(0.2, '#292929')};
    color: #fff;
    border-color: #292929;
    background-color: #292929;
  }
`

export const OrangeSecondary = styled(BaseButton)`
  background-color: ${primaryColor};
  color: white;
  border: 1px solid ${primaryColor};
  transition: all 0.4s;

  :active {
    background-color: ${lighten(0.4, '#292929')};
    color: #fff;
    border-color: #292929;
    background-color: #292929;
  }

  :hover {
    background-color: ${lighten(0.4, '#292929')};
    color: #fff;
    border-color: #292929;
    background-color: #292929;
  }
`
