import styled from 'styled-components'

export const Container = styled.section``

export const Main = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 5rem;
  margin: 2rem 0;
`
